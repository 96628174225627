import { Route, Routes } from 'react-router-dom';
import {
  MEETING_APPS_URLS,
  ZOOM_APP_URLS,
} from 'src/components/navbar/app.urls';
import { ZoomApp } from './zoom-app/ZoomApp';
import { LoginScreen } from './zoom-app/components/login/LoginScreen';
import { LivePollListScreen } from './zoom-app/components/list-screen/LivePollListScreen';
import { TakeLivePoll } from './zoom-app/components/take-livepoll/take-livepoll';

export const MeetingApps = () => {
  return (
    <Routes>
      <Route path={MEETING_APPS_URLS.ZOOM_APP_HOME} element={<ZoomApp />} />
      <Route path={ZOOM_APP_URLS.LOGIN} element={<LoginScreen />} />
      <Route
        path={ZOOM_APP_URLS.LIST_SCREEN}
        element={<LivePollListScreen />}
      />
      <Route path={ZOOM_APP_URLS.TAKE_LIVEPOLL} element={<TakeLivePoll />} />
    </Routes>
  );
};
