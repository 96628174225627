interface Props {
  width?: string;
  height?: string;
}

export const LikeSvg = ({ width, height }: Props) => {
  const widthInPx = width || '14px';
  const heightInPx = height || '14px';

  const image = (
    <svg
      width={widthInPx}
      height={heightInPx}
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Top Voted Icon</title>
      <g
        id="Livefeed-New-Proposal-Oct-2021"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Live-Feed-2-Copy"
          transform="translate(-112.000000, -465.000000)"
          fill="currentColor"
        >
          <g id="Group-5-Copy" transform="translate(111.000000, 462.000000)">
            <g
              id="QPIcons/Like/16px-Copy"
              transform="translate(0.000000, 2.000000)"
            >
              <path
                d="M5,15 L5,1 L7,1 C8.1045695,1 9,1.8954305 9,3 L9,5 L13,5 C14.1045695,5 15,5.8954305 15,7 L15,13 C15,14.1045695 14.1045695,15 13,15 L5,15 Z M1,5 L4,5 L4,15 L1,15 L1,5 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
  return image;
};
