import { PIXEL_CONSTANTS } from '../constants/pixel-constants';

export const getFontSizeForSwipeQuestionCard = (
  title: string,
  isDesktopView: boolean,
) => {
  const titleLength = title?.length || 0;

  if (isDesktopView) {
    const smallText = titleLength > 23 && titleLength <= 52;
    const mediumText = titleLength > 52 && titleLength <= 90;
    const largeText = titleLength > 90;
    if (smallText) {
      return {
        fontSize: PIXEL_CONSTANTS.sixteen,
        lineHeight: PIXEL_CONSTANTS.twentyfour,
      };
    } else if (mediumText) {
      return {
        fontSize: PIXEL_CONSTANTS.fourteen,
        lineHeight: PIXEL_CONSTANTS.twentyone,
      };
    } else if (largeText) {
      return {
        fontSize: PIXEL_CONSTANTS.twelve,
        lineHeight: PIXEL_CONSTANTS.eighteen,
      };
    }
    return {
      fontSize: PIXEL_CONSTANTS.eighteen,
      lineHeight: PIXEL_CONSTANTS.twentyfour,
    };
  } else {
    return {
      fontSize: PIXEL_CONSTANTS.twelve,
      lineHeight: PIXEL_CONSTANTS.sixteen,
    };
  }
};
