import { useEffect, useState } from 'react';
import { SwipeQuestionCard } from 'src/interfaces/swipe-question-card.interface';
import { SwipeQuestionOption } from 'src/interfaces/swipe-question-option.interface';
import styles from './LpSwipeQuestionChartForPoll.module.css';
import ascendingSvg from '../../../images/ascending.svg';
import descendingSvg from '../../../images/descending.svg';
import swipeLeftSvg from '../../../images/swipe-left.svg';
import swipeRightSvg from '../../../images/swipe-right.svg';
import { SwipeQuestionFinishDataWithRank } from '../../../interfaces/swipe-question-finish-data-with-rank.interface';
import { ChartView } from '../../../enums/chart-view.enum';
import { SortOrder } from '../../../enums/sort-order.enum';
import { SwipeQuestionResponse } from 'src/interfaces/swipe-question-response.interface';
import { WuTooltip } from '@npm-questionpro/wick-ui-lib';
import { getAnswerText } from '../../../utils/getAnswerText.util';
import { useScreenResize } from '../../../utils/getScreenSize.util';
import { LpDropdown } from '../../../components/dropdown/LpDropdown';
import { getFontSizeForSwipeQuestionCard } from '../../../utils/getFontSizeForSwipeQuestionCard.util';
import { isDesktopDevice } from '../../../utils/isDesktopDevice.util';

interface Props {
  swipeQuestion: {
    swipeOptions: SwipeQuestionOption;
    cards: SwipeQuestionCard[];
  };
  responses: SwipeQuestionResponse[];
  isReportScreen?: boolean;
  text: {
    sortBy: string;
    default: string;
    accuracy: string;
  };
}

export const LpSwipeQuestionChartForPoll = ({
  swipeQuestion,
  responses,
  isReportScreen,
  text,
}: Props) => {
  const [sortedResponsesWithRank, setSortedResponsesWithRank] = useState<
    SwipeQuestionFinishDataWithRank[]
  >([]);

  const [updatedSortedResponsesWithRank, setUpdatedSortedResponsesWithRank] =
    useState<SwipeQuestionFinishDataWithRank[]>([]);

  const [currentView, setCurrentView] = useState<ChartView>(
    ChartView.CHRONOLOGICAL,
  );
  const { width } = useScreenResize();
  const isDesktopView = isDesktopDevice(width);
  const [order, setOrder] = useState<SortOrder>(SortOrder.DESC);
  const leftSideColor = '#FF7681';
  const leftSideText = swipeQuestion.swipeOptions.left.text;
  const rightSideText = swipeQuestion.swipeOptions.right.text;
  const rightSideColor = '#3FCA7F';
  const cards = swipeQuestion.cards;
  const lastRank = cards.length;

  useEffect(() => {
    const prepareSwipeQuestionResult = (
      swipeQuestionResponses?: SwipeQuestionResponse[],
    ) => {
      if (!swipeQuestionResponses) {
        return [];
      }
      const rankedResponses = swipeQuestionResponses
        .map(item => ({
          ...item,
          percentage: getPercentage(
            Number(item.rightCount),
            Number(item.leftCount),
          ),
        }))
        .sort((a, b) => Number(b.percentage) - Number(a.percentage))
        .map((item, index) => ({
          ...item,
          rank: index + 1,
        }));

      const cardIdsWithResponses = new Set(
        swipeQuestionResponses.map(r => r.cardId),
      );
      const cardsWithoutResponses = cards.filter(
        card => !cardIdsWithResponses.has(card.id),
      );

      const lastRank = rankedResponses.length;
      cardsWithoutResponses.forEach((card, index) => {
        const currentIndex = index + 1;
        rankedResponses.push({
          cardId: card.id,
          rightCount: 0,
          leftCount: 0,
          percentage: 0,
          rank: lastRank + currentIndex,
        });
      });

      return rankedResponses;
    };

    const responsesWithRank = prepareSwipeQuestionResult(responses);

    setSortedResponsesWithRank(responsesWithRank);
  }, [cards, responses]);

  useEffect(() => {
    if (
      currentView === ChartView.CHRONOLOGICAL &&
      sortedResponsesWithRank.length > 0
    ) {
      const prepareCardView = (
        responses: SwipeQuestionFinishDataWithRank[],
      ) => {
        return cards.map(
          card => responses.find(res => res.cardId === card.id)!,
        );
      };
      const response = prepareCardView(sortedResponsesWithRank);
      if (order === SortOrder.ASC) {
        setUpdatedSortedResponsesWithRank([...response].reverse());
      } else {
        setUpdatedSortedResponsesWithRank(response);
      }
    } else {
      if (order === SortOrder.ASC) {
        setUpdatedSortedResponsesWithRank(
          [...sortedResponsesWithRank].reverse(),
        );
      } else {
        setUpdatedSortedResponsesWithRank(sortedResponsesWithRank);
      }
    }
  }, [cards, currentView, order, sortedResponsesWithRank]);

  const prepareDataForBar = (cardId: string) => {
    const responseForCard = updatedSortedResponsesWithRank.find(
      response => response.cardId === cardId,
    );
    const rightCount = Number(responseForCard?.rightCount) || 0;
    const leftCount = Number(responseForCard?.leftCount) || 0;
    const rightSidePercentage = getPercentage(rightCount, leftCount);
    const leftSidePercentage = getPercentage(leftCount, rightCount);
    return {
      left: leftSidePercentage,
      right: rightSidePercentage,
      rightCount,
      leftCount,
      rank: Number(responseForCard?.rank || 0),
    };
  };

  const getStyleForRankWithoutImage = (rank: number, lastRank: number) => {
    if (rank === 1) {
      return `${styles.firstRankForEmptyCard}`;
    } else if (rank === lastRank) {
      return `${styles.lastRankForEmptyCard}`;
    } else {
      return `${styles.cardRankForEmptyCard}`;
    }
  };

  const getStyleForRankWithImage = (rank: number, lastRank: number) => {
    if (rank === 1) {
      return `${styles.firstRank}`;
    } else if (rank === lastRank) {
      return `${styles.lastRank}`;
    } else {
      return `${styles.cardRank}`;
    }
  };

  const getStyleForCardWithImage = (rank: number, lastRank: number) => {
    if (rank === 1) {
      return `${styles.cardImagePic} ${styles.cardBorder}`;
    } else if (rank === lastRank) {
      return `${styles.cardImagePic} ${styles.lastCardBorder}`;
    } else {
      return `${styles.cardImagePic}`;
    }
  };
  const getStyleForCardWithoutImage = (rank: number, lastRank: number) => {
    if (rank === 1) {
      return `${styles.emptyCardImage} ${styles.cardBorderForEmptyCard}`;
    } else if (rank === lastRank) {
      return `${styles.emptyCardImage} ${styles.lastCardBorderForEmptyCard}`;
    } else {
      return `${styles.emptyCardImage}`;
    }
  };

  const displayRank = (
    imageUrl: string | undefined,
    rank: number,
    lastRank: number,
  ) => {
    return (
      <>
        {imageUrl ? (
          <div className={getStyleForRankWithImage(rank, lastRank)}>
            # <span>{rank}</span>
          </div>
        ) : (
          <div className={getStyleForRankWithoutImage(rank, lastRank)}>
            # <span>{rank}</span>
          </div>
        )}
      </>
    );
  };

  const displayCardImage = (
    imageUrl: string | undefined,
    rank: number,
    lastRank: number,
  ) => {
    return (
      <>
        {imageUrl ? (
          <>
            <img
              src={imageUrl}
              className={getStyleForCardWithImage(rank, lastRank)}
              alt="card pic"
            />
          </>
        ) : (
          <div className={getStyleForCardWithoutImage(rank, lastRank)}></div>
        )}
      </>
    );
  };

  const displayBars = (left: number, right: number) => {
    return (
      <div className={styles.bar}>
        <div
          style={{ width: `${left}%`, background: leftSideColor }}
          className={
            left === 100 ? styles.borderRadius : styles.leftSideBorderRadius
          }
        ></div>
        <div
          style={{ width: `${right}%`, background: rightSideColor }}
          className={
            right === 100 ? styles.borderRadius : styles.rightSideBorderRadius
          }
        ></div>
      </div>
    );
  };

  const getCardRow = (cardId: string) => {
    const card = swipeQuestion.cards.find(card => card.id === cardId);
    const { left, right, leftCount, rightCount, rank } = prepareDataForBar(
      card!.id,
    );

    const imageUrl = card?.image?.url;
    return (
      <div className={styles.cardRow}>
        <div className={styles.leftSide}>
          <div className={styles.cardImage}>
            {displayRank(imageUrl, rank, lastRank)}

            {displayCardImage(imageUrl, rank, lastRank)}
          </div>
          <div
            className={rank === 1 ? styles.boldCardText : styles.cardText}
            data-testid="card title"
            style={getFontSizeForSwipeQuestionCard(
              card?.title || '',
              isDesktopView,
            )}
          >
            {getAnswerText({
              text: card?.title,
              image: { url: card?.image?.url || '' },
            })}
          </div>
        </div>

        <div className={styles.rightSide}>
          <div className={styles.leftAndRightTextRow}>
            <div style={{ color: leftSideColor }}>{leftCount}</div>
            <div style={{ color: rightSideColor }}>{rightCount}</div>
          </div>
          {displayBars(left, right)}
        </div>
      </div>
    );
  };

  const changeOrder = (sortOrder: SortOrder) => {
    setOrder(sortOrder);
  };

  const changeView = (view: ChartView) => {
    if (view === currentView) {
      return;
    }
    setCurrentView(view);
  };

  const getDropDownText = () => {
    if (currentView === ChartView.CHRONOLOGICAL) {
      return text.default;
    } else if (currentView === ChartView.RIGHT_SWIPES_RESPONSE_RATE) {
      return text.accuracy;
    }
    return text.default;
  };

  const topRow = () => {
    return (
      <div className={styles.header}>
        <div className={styles.headerRow}>
          <div className={styles.leftSideForHeader}>
            <div className={styles.sortByContainer}>
              <div>{text.sortBy}</div>
              <div>
                <LpDropdown
                  text={getDropDownText()}
                  customDropdownBtnClass={styles.dropDown}
                  customDropdownContentClass={styles.dropDownContent}
                  customDropdownTextClass={styles.dropdownText}
                >
                  <ul>
                    <li
                      className={styles.dropDownItem}
                      onClick={() => changeView(ChartView.CHRONOLOGICAL)}
                      key="default"
                    >
                      {text.default}
                    </li>

                    <li
                      className={styles.dropDownItem}
                      onClick={() =>
                        changeView(ChartView.RIGHT_SWIPES_RESPONSE_RATE)
                      }
                      key="accuracy"
                    >
                      {text.accuracy}
                    </li>
                  </ul>
                </LpDropdown>
              </div>
            </div>
            <WuTooltip content={'Reverse order'} position="bottom">
              <div>
                {order === SortOrder.DESC ? (
                  <img
                    src={descendingSvg}
                    onClick={() => changeOrder(SortOrder.ASC)}
                    alt="desc order"
                    className={styles.icon}
                  />
                ) : (
                  <img
                    src={ascendingSvg}
                    onClick={() => changeOrder(SortOrder.DESC)}
                    alt="asc order"
                    className={styles.icon}
                  />
                )}
              </div>
            </WuTooltip>
          </div>
          <div className={styles.rightSideForHeader}>
            <div className={styles.swipeOptionText}>
              <WuTooltip content={leftSideText} position="bottom">
                <img
                  src={swipeLeftSvg}
                  className={styles.leftSideImage}
                  alt="swipe left"
                />
                <span className={styles.leftSideText}> {leftSideText}</span>
              </WuTooltip>
            </div>
            <div className={styles.swipeOptionText}>
              <WuTooltip content={rightSideText} position="bottom">
                <span className={styles.rightSideText}> {rightSideText}</span>
                <img
                  src={swipeRightSvg}
                  className={styles.rightSideImage}
                  alt="swipe right"
                />
              </WuTooltip>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container} data-testid="swipe question finished">
      <>{topRow()}</>
      <div
        className={`${styles.innerContainer} ${
          isReportScreen ? styles.innerContainerForReport : ''
        }`}
      >
        {updatedSortedResponsesWithRank.map(response => {
          return <div key={response.cardId}>{getCardRow(response.cardId)}</div>;
        })}
      </div>
    </div>
  );
};

const getPercentage = (value: number, remainingValue: number) => {
  const total = value + remainingValue;
  if (value === 0) {
    return 0;
  }
  const percentage = (value * 100) / total;
  return percentage;
};
