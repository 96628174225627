import { useSwipeQuestionResult } from 'src/hooks/store-hooks';
import styles from './SwipeQuestionFinishedScreenForZoom.module.css';
import { LivePollSessionHeaderForZoom } from '../../../session-header/LivePollSessionHeaderForZoom';
import { LpSwipeQuestionFinishedScreen } from '@livepolls/ui-components/src/components/swipe-question-finished/LpSwipeQuestionFinishedScreen';
import { t } from 'i18next';
import { useCurrentQuestion } from 'src/contexts/runLivePollSessionContext';
import { ISwipeQuestion } from 'src/models/swipe-question.interface';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';

interface Props {
  questionTitle: string;
  questionId: string;
  livePollId: number;
  livePollSessionId: number;
  isQuiz: boolean;
}

export const SwipeQuestionFinishedScreenForZoom = ({
  questionTitle,
  questionId,
  livePollId,
  livePollSessionId,
  isQuiz,
}: Props) => {
  const {
    isLoading: isLoadingQuestionResult,
    isError,
    error,
    data: questionResult,
  } = useSwipeQuestionResult(livePollId, livePollSessionId, questionId);
  const currentQuestion = useCurrentQuestion() as ISwipeQuestion;

  if (isLoadingQuestionResult) {
    return <LpSpinner />;
  }

  if (isError || error) {
    return <div>Something went wrong</div>;
  }

  return (
    <>
      <LivePollSessionHeaderForZoom />
      <div className={styles.container}>
        <div className={styles.questionText}>
          <strong> Question: </strong>
          {questionTitle}
        </div>
        {questionResult?.swipeQuestionResponses &&
          questionResult?.swipeQuestionResponses?.length > 0 && (
            <LpSwipeQuestionFinishedScreen
              isQuiz={isQuiz}
              swipeQuestion={currentQuestion}
              text={{
                initializing: t('initializing'),
                noDataAvailable: t('noDataAvailable'),
                sortBy: t('sortBy'),
                default: t('default'),
                accuracy: t('accuracy'),
                swipeQuestionCorrectText: t('swipeQuestionCorrectText'),
                swipeQuestionIncorrectText: t('swipeQuestionIncorrectText'),
                teamRankings: t('teamRankings'),
                individualView: t('individualView'),
                teamsView: t('teamsView'),
              }}
              questionResult={questionResult}
              isTeamsEnabled={false}
            />
          )}

        {(!questionResult ||
          questionResult.swipeQuestionResponses.length === 0) && (
          <div className={styles.emptyState}>No responses</div>
        )}
        <></>
      </div>
    </>
  );
};
