import { QuestionType } from '@livepolls/ui-components/src/enums/question-type';
import {
  useCurrentQuestion,
  useFinishLivePollSessionMutation,
  useRunLivePollSessionContext,
  useStartQuestionMutation,
} from 'src/contexts/runLivePollSessionContext';
import { ChoiceQuestionFinishedScreenForZoom } from './choice/ChoiceQuestionChart';
import styles from './QuestionFinishedScreen.module.css';
import { hasNextQuestion } from 'src/utils/hasNextQuestion';
import { WuButton } from '@npm-questionpro/wick-ui-lib';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import { LiveFeedQuestionFinishedScreenForZoom } from './livefeed/LiveFeedQuestionFinishedForZoom';
import { SwipeQuestionFinishedScreenForZoom } from './swipe/SwipeQuestionFinishedScreenForZoom';
import { WordCloudQuestionFinishedScreenForZoom } from './word-cloud/WordCloudQuestionFinishedScreenForZoom';
import { LivePollType } from '@livepolls/ui-components/src/enums/livepoll-type.enum';
import { CountDownForZoom } from '../../countdown/CountDownForZoom';

export const QuestionFinishedScreen = () => {
  const question = useCurrentQuestion();
  const {
    livePoll,
    handleStartCountdown,
    showQuestionCountdown,
    livePollSession,
  } = useRunLivePollSessionContext();
  const finishLivePollSessionMutation = useFinishLivePollSessionMutation();
  const questionType = question.type;
  const startQuestionMutation = useStartQuestionMutation();

  const handleFinishLivePollSession = async () => {
    finishLivePollSessionMutation.isIdle &&
      (await finishLivePollSessionMutation.mutateAsync());
  };

  if (showQuestionCountdown.show || startQuestionMutation.isLoading) {
    return <CountDownForZoom />;
  }

  const zoomSessionFooter = () => {
    const isNextQuestionAvailable = hasNextQuestion(livePoll, question.id);
    const buttonText = isNextQuestionAvailable
      ? 'Next question'
      : 'Finish session';

    return (
      <div className={styles.footer}>
        <WuButton
          variant="primary"
          onClick={() =>
            isNextQuestionAvailable
              ? handleStartCountdown()
              : handleFinishLivePollSession()
          }
          icon={<span className={`wm-arrow-right ${styles.rightArrow}`} />}
          iconPosition="right"
        >
          {buttonText}
        </WuButton>
      </div>
    );
  };

  if (finishLivePollSessionMutation.isLoading) {
    return <LpSpinner />;
  }

  return (
    <div className={styles.container}>
      {questionType === QuestionType.CHOICE && (
        <ChoiceQuestionFinishedScreenForZoom displayAnswer={true} />
      )}
      {questionType === QuestionType.LIVE_FEED && (
        <LiveFeedQuestionFinishedScreenForZoom
          questionTitle={question.title || ''}
          questionId={question.id}
          livePollId={livePoll.id}
          livePollSessionId={livePollSession.id}
        />
      )}
      {questionType === QuestionType.WORD_CLOUD && (
        <WordCloudQuestionFinishedScreenForZoom
          questionTitle={question.title || ''}
          questionId={question.id}
          livePollId={livePoll.id}
          livePollSessionId={livePollSession.id}
        />
      )}
      {questionType === QuestionType.SWIPE && (
        <SwipeQuestionFinishedScreenForZoom
          questionTitle={question.title || ''}
          questionId={question.id}
          livePollId={livePoll.id}
          livePollSessionId={livePollSession.id}
          isQuiz={livePoll.type === LivePollType.QUIZ}
        />
      )}
      {zoomSessionFooter()}
    </div>
  );
};
