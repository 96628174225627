import { IAnswer } from '@livepolls/ui-components/src/interfaces/answer.interface';
import { useQuestionResult } from 'src/hooks/store-hooks';
import { useDebounce } from 'src/hooks/useDebounce';
import { LivePollSessionHeaderForZoom } from '../../../session-header/LivePollSessionHeaderForZoom';
import { ChoiceQuestionLiveChartForZoom } from '../../../choice-question-live-chart/ChoiceQuestionLiveChart';
import styles from './ChoiceQuestionStarted.module.css';

interface Props {
  questionTitle: string;
  answers: IAnswer[];
  questionId: string;
  livePollId: number;
  livePollSessionId: number;
}

export const ChoiceQuestionStartedForZoom = ({
  questionTitle,
  answers,
  questionId,
  livePollId,
  livePollSessionId,
}: Props) => {
  const debouncedQuestionId = useDebounce(questionId, 500);

  const {
    isError,
    error,
    data: questionResult,
  } = useQuestionResult(livePollId, livePollSessionId, debouncedQuestionId);

  if (isError || error) {
    return <div>Something went wrong. Please try again</div>;
  }

  return (
    <>
      <LivePollSessionHeaderForZoom />
      <div className={styles.container}>
        <div className={styles.questionText}>
          <strong> Question: </strong>
          {questionTitle}
        </div>

        {questionResult?.responses && questionResult?.responses.length > 0 && (
          <ChoiceQuestionLiveChartForZoom
            answers={answers}
            responses={questionResult?.responses}
          />
        )}
      </div>
    </>
  );
};
