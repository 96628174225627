import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import { LivePollSessionState } from '@livepolls/ui-components/src/enums/livepoll-session-state.enum';
import { useNavigate, useParams } from 'react-router-dom';
import { RunLivePollSessionContextProvider } from 'src/contexts/runLivePollSessionContext';
import { SocketContextProvider } from 'src/contexts/socketContext';
import {
  useLivePoll,
  useLivePollSession,
  useQuestionResponseTimer,
} from 'src/hooks/store-hooks';
import { WaitingRoomScreen } from './waiting-room/WaitingRoomScreen';
import styles from './take-livepoll.module.css';
import { QuestionStartedScreen } from './question-started/QuestionStartedScreen';
import { QuestionFinishedScreen } from './question-finished/QuestionFinishedScreen';
import { useEffect } from 'react';

export const TakeLivePoll = () => {
  const navigate = useNavigate();

  const params = useParams<{
    livePollId: string;
    livePollSessionId: string;
  }>();

  const livePollId = +params.livePollId!;
  const livePollSessionId = +params.livePollSessionId!;

  const {
    isLoading: isLivePollSessionLoading,
    error: livePollSessionError,
    data: livePollSession,
  } = useLivePollSession(livePollId, livePollSessionId);

  const {
    isLoading: isLivePollLoading,
    error: livePollError,
    data: livePoll,
  } = useLivePoll(livePollId);

  const questionResponseTimerId = !!livePollSession
    ? livePollSession.questionResponseTimerId
    : 0;

  const {
    isLoading: isLoadingQuestionResponseTimer,
    error: questionResponseTimerError,
  } = useQuestionResponseTimer(
    livePollId,
    livePollSessionId,
    questionResponseTimerId,
  );
  const activeState = livePollSession?.state;

  useEffect(() => {
    if (activeState === LivePollSessionState.COMPLETED) {
      const url = `/zoom/list-screen`;
      navigate(url);
    }
  }, [activeState, navigate]);

  if (questionResponseTimerError || livePollError || livePollSessionError) {
    return <div>Something went wrong. please try again.</div>;
  }

  if (
    isLivePollSessionLoading ||
    isLivePollLoading ||
    isLoadingQuestionResponseTimer
  ) {
    return <LpSpinner />;
  }

  return (
    <SocketContextProvider>
      <RunLivePollSessionContextProvider
        livePoll={livePoll!}
        livePollSession={livePollSession!}
        presenterNotesWindow={null}
      >
        <div className={styles.container}>
          {activeState === LivePollSessionState.QUESTION_FINISHED && (
            <QuestionFinishedScreen />
          )}

          {activeState === LivePollSessionState.QUESTION_STARTED && (
            <QuestionStartedScreen />
          )}

          {activeState === LivePollSessionState.WAITING_ROOM && (
            <WaitingRoomScreen />
          )}
        </div>
      </RunLivePollSessionContextProvider>
    </SocketContextProvider>
  );
};
