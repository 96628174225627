import { IAnswer } from '../interfaces/answer.interface';

export const getAnswerText = (answer: Partial<IAnswer>) => {
  const answerText = answer?.text;
  const answerHasImage = !!(
    answer.image &&
    answer.image?.url &&
    answer.image?.url.length > 0
  );

  if (answerText) {
    return answerText;
  } else {
    if (answerHasImage) {
      return '';
    } else {
      return 'Untitled';
    }
  }
};
