import { useRunLivePollSessionContext } from 'src/contexts/runLivePollSessionContext';
import styles from './LivePollSessionHeaderForZoom.module.css';
import { addZoomAppInvitation } from 'src/hooks/store-hooks';
import { sendAppInvitationToAllParticipants } from 'src/utils/zoom-sdk.utils';
import { WuButton } from '@npm-questionpro/wick-ui-lib';

export const LivePollSessionHeaderForZoom = () => {
  const { livePollSession } = useRunLivePollSessionContext();
  const livePollSessionName = livePollSession?.name;
  const pin = livePollSession?.pin;
  const livePollSessionId = livePollSession.id;

  const sendInvitationAddEntryInDatabase = async () => {
    const response = await sendAppInvitationToAllParticipants();
    const invitationId = response?.invitationId || '';
    const meetingId = response?.meetingId || '';

    if (invitationId || meetingId) {
      await addZoomAppInvitation(livePollSessionId, invitationId, meetingId);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.livePollSessionName}>{livePollSessionName}</div>
        <div className={styles.pinRow}>
          <div className={styles.pin}>PIN: {pin}</div>
          <div>
            <WuButton
              variant="secondary"
              onClick={() => sendInvitationAddEntryInDatabase()}
            >
              Send invitation
            </WuButton>
          </div>
        </div>
      </div>
    </div>
  );
};
