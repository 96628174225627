import { WuButton } from '@npm-questionpro/wick-ui-lib';
import styles from './LoginScreen.module.css';

export const LoginScreen = () => {
  const handleLogin = () => {};
  const handleSignUp = () => {};

  return (
    <div className={styles.container}>
      <div className={styles.welcomeText}>Welcome to LivePolls</div>
      <div className={styles.description}>
        The perfect polling solution for your company meetings and events.
      </div>
      <div className={styles.buttonRow}>
        <WuButton variant="primary" onClick={handleLogin}>
          Log In
        </WuButton>
        <WuButton variant="secondary" onClick={handleSignUp}>
          Sign up
        </WuButton>
      </div>
    </div>
  );
};
