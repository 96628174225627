import zoomSdk, { RunningContext } from '@zoom/appssdk';

const ZOOM_SUCCESS_MSG_RESPONSE = 'success';
const ZOOM_IN_MEETING_RUNNING_CONTEXT = 'inMeeting';
export const ZOOM_OPENED_IN_MOBILE = 'mobile';

export const initializeZoomSdk = () => {
  return zoomSdk.config({
    capabilities: [
      'openUrl',
      'sendAppInvitationToAllParticipants',
      'getMeetingUUID',
    ],
  });
};

export const expandZoomApp = async (
  runningContext?: RunningContext,
): Promise<boolean> => {
  if (!runningContext || runningContext !== ZOOM_IN_MEETING_RUNNING_CONTEXT) {
    return true;
  }

  const messageResponse = (
    await zoomSdk.expandApp({
      action: 'expand',
    })
  ).message;
  if (messageResponse.toLocaleLowerCase() === ZOOM_SUCCESS_MSG_RESPONSE) {
    return true;
  }
  return false;
};

export const openUrlInBrowserFromZoomApp = async (
  apiUrl: string,
): Promise<boolean> => {
  const messageResponse = (await zoomSdk.openUrl({ url: apiUrl })).message;

  if (messageResponse.toLocaleLowerCase() === ZOOM_SUCCESS_MSG_RESPONSE) {
    return true;
  }
  return false;
};

export const sendAppInvitationToAllParticipants = async () => {
  try {
    const res = await zoomSdk.sendAppInvitationToAllParticipants();
    const { meetingUUID: meetingId } = await zoomSdk.getMeetingUUID();
    console.log(res, meetingId);
    return { invitationId: res.invitationUUID, meetingId };
  } catch (err) {
    console.log('err 1', err);
  }
};

export const isZoomRunningInMobile = (product?: string): boolean =>
  product === ZOOM_OPENED_IN_MOBILE;
