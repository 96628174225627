import {
  useAddLivePollSessionMutation,
  useLivePolls,
} from 'src/hooks/store-hooks';
import React, { useEffect } from 'react';
import { IFetchLivePollsSortingParams } from 'src/models/livepoll-list-sorting-params.interface';

import styles from './LivePollListScreen.module.css';
import { LivePollsView } from 'src/models/livepolls-view.enum';
import { ILivePollSessionSetting } from 'src/models/livepoll-session-setting.interface';
import { useNavigate } from 'react-router-dom';
import { formatPluralizedText } from 'src/utils/getPluralizedText.util';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';

const DEFAULT_PAGER = {
  perPage: 100,
  pageIndex: 1,
};

export const LivePollListScreen = () => {
  const [sortingParams] = React.useState<IFetchLivePollsSortingParams>({});
  const addLivePollSessionMutation = useAddLivePollSessionMutation();
  const navigate = useNavigate();
  const {
    isError,
    isLoading,
    error,
    data: livePollsData,
  } = useLivePolls(sortingParams, LivePollsView.LIST, DEFAULT_PAGER);

  useEffect(() => {
    const livePollSession = addLivePollSessionMutation.data;
    if (livePollSession) {
      const url = `/zoom/${livePollSession.livePollId}/livepoll-sessions/${livePollSession.id}/run`;
      navigate(url);
    }
  }, [addLivePollSessionMutation.data, navigate]);

  const handleAddLivePollSession = async (
    livePollId: number,
    livePollName?: string,
  ) => {
    const data: ILivePollSessionSetting = {
      name: livePollName || 'Zoom Session',
      responseTimeLimitInSecs: 60,
      anonymousResponses: false,
      displayDifficultyLevels: false,
      teamSetting: { enabled: false },
      presenterNotes: false,
    };
    addLivePollSessionMutation.mutate({
      livePollId,
      data: { ...data },
    });
  };

  if (isLoading) {
    return <LpSpinner />;
  }
  if (isError || error) {
    return <div>Something went wrong</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.myPollsText}>My Polls</div>
      {livePollsData?.livePolls.map(livePoll => {
        return (
          <div className={styles.card} key={livePoll.id}>
            <div>
              <div className={styles.livePollTitle}>{livePoll.title}</div>
              <div className={styles.cardInfo}>
                <div className={styles.numberOfQuestions}>
                  {formatPluralizedText(
                    livePoll.questionCount || 0,
                    'question',
                  )}
                </div>
                <div>
                  {formatPluralizedText(
                    livePoll.totalParticipants,
                    'participant',
                  )}
                </div>
              </div>
            </div>
            <div className={styles.playCircleRow}>
              <span
                className={`wm-play-circle ${styles.playCircle}`}
                onClick={() =>
                  handleAddLivePollSession(livePoll.id, livePoll.title)
                }
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
