import React from 'react';
import Highcharts from 'highcharts';
import wordCloud from 'highcharts/modules/wordcloud';
import { WordCloudQuestionResponse } from '../../interfaces/word-cloud-question-response.interface';
import { LpTopThreeLeaderboard } from '../livepoll-question-reports/components/LpTopThreeLeaderboard';
import { useScreenResize } from '../../utils/getScreenSize.util';
import styles from './LpWordCloud.module.css';
import { isDesktopDevice } from '../../utils/isDesktopDevice.util';

interface Props {
  wordCloudQuestionResponses: WordCloudQuestionResponse[];
  text: {
    mentions: string;
    popularMentions: string;
  };
}

export const LpWordCloudImage = React.memo(
  ({ wordCloudQuestionResponses, text }: Props) => {
    const ref = React.useRef<HTMLDivElement | null>(null);
    const { width } = useScreenResize();
    const isDesktopView = isDesktopDevice(width);
    const isMobileView = !isDesktopView;

    const updatedResponses = addFontSizeAndColorToResponses(
      wordCloudQuestionResponses,
      isMobileView,
    );
    const totalResponseCount = wordCloudQuestionResponses.reduce(
      (accumulator, currentValue) => accumulator + currentValue.weight,
      0,
    );

    React.useEffect(() => {
      if (!ref.current) {
        return;
      }

      wordCloud(Highcharts);

      Highcharts.chart(ref.current, {
        series: [
          {
            type: 'wordcloud',
            data: updatedResponses,
            name: text.mentions,
            rotation: {
              from: 0,
              to: 270,
            },
            style: {
              fontFamily: "'Fira Sans', sans-serif",
            },
          },
        ],
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        chart: {
          margin: [8, 8, 8, 8], // Remove all chart margins
          backgroundColor: '#fff',
          type: 'wordcloud',
          events: {
            load: function () {
              // Add hover effects on load
              const chart = this;
              chart.series[0].points.forEach((point: any) => {
                if (point.graphic && point.graphic.on) {
                  point.graphic.on('mouseover', function () {
                    // Highlight hovered word
                    point.graphic.css({ opacity: 1 });

                    // Dim other words
                    chart.series[0].points.forEach((p: any) => {
                      if (p !== point) {
                        p.graphic.css({ opacity: 0.5 });
                      }
                    });
                  });

                  point.graphic.on('mouseout', function () {
                    // Reset opacity for all words
                    chart.series[0].points.forEach((p: any) => {
                      p.graphic.css({ opacity: 1 });
                    });
                  });
                }
              });
            },
          },
        },
        tooltip: {
          hideDelay: true,
          shadow: false,
          borderWidth: 0,
          backgroundColor: '#555',
          borderColor: '#555',
          borderRadius: 0,
          useHTML: true,

          formatter: function () {
            const word = this.point.name.replace(/<[^>]+>/g, '');
            const percent = Math.floor(
              ((this.point as any).weight / totalResponseCount) * 100,
            );
            return (
              '<div style="color: #fff; display: flex; flex-direction: column">' +
              '<div style="font-size: 12px;">' +
              word +
              '</div>' +
              '<div>' +
              percent +
              '% (' +
              (this.point as any).weight +
              ' mentions)' +
              '</div>' +
              '</div>'
            );
          },
        },
      });
    }, [updatedResponses, text, totalResponseCount]);

    const showWordCloudLeaderboard = wordCloudQuestionResponses.length > 3;

    const calculatePercentage = (value: number) => {
      return Math.floor((value / totalResponseCount) * 100);
    };

    const topThreeLeaderboardArray =
      wordCloudQuestionResponses?.slice(0, 3).map(item => {
        return {
          name: item.name,
          value: calculatePercentage(item.weight) + '%',
        };
      }) || [];

    const classNames = showWordCloudLeaderboard
      ? styles.container
      : styles.smallContainer;

    if (!updatedResponses || updatedResponses.length === 0) {
      return <div className={styles.noResponsesText}>No responses</div>;
    }

    return (
      <div>
        {showWordCloudLeaderboard && (
          <LpTopThreeLeaderboard
            items={topThreeLeaderboardArray}
            title={text.popularMentions}
            testIdForName="topItemForWordCloudQuestion"
            testIdForScore="topItemScoreForWordCloudQuestion"
          />
        )}
        <div
          ref={ref}
          data-testid="wordCloudQuestionResultChart"
          className={classNames}
        />
      </div>
    );
  },
);

const addFontSizeAndColorToResponses = (
  responses: WordCloudQuestionResponse[],
  isMobile: boolean,
) => {
  let currentRank = 1;
  const rankedResponses = responses.map((response, index) => {
    if (index > 0 && response.weight !== responses[index - 1].weight) {
      currentRank = index + 1; // Update rank only when weight differs
    }
    return { ...response, rank: currentRank };
  });

  return rankedResponses.map(response => {
    const style = getStyleByPosition(response.rank, isMobile);
    const wordWithStyling = {
      name: `<span style="font-size: ${style.fontSize}; font-weight: ${style.fontWeight}; color: ${style.color}">${response.name}</span>`,
      weight: response.weight,
    };

    return wordWithStyling;
  });
};

const getStyleByPosition = (position: number, isMobile: boolean) => {
  switch (true) {
    case position === 1:
      return {
        fontSize: isMobile ? '40px' : '64px',
        color: '#1B3380',
        fontWeight: 500,
      };
    case position === 2:
      return {
        fontSize: isMobile ? '32px' : '48px',
        color: '#1B87E6',
        fontWeight: 500,
      };
    case position === 3:
      return {
        fontSize: isMobile ? '24px' : '40px',
        color: '#1B87E6',
        fontWeight: 400,
      };
    case position >= 4 && position <= 10:
      return {
        fontSize: isMobile ? '20px' : '32px',
        color: '#1B87E6',
        fontWeight: 400,
      };
    case position >= 11 && position <= 15:
      return {
        fontSize: isMobile ? '18px' : '24px',
        color: '#1B87E6',
        fontWeight: 400,
      };
    case position >= 16 && position <= 20:
      return {
        fontSize: isMobile ? '16px' : '20px',
        color: '#545E6B',
        fontWeight: 400,
      };
    case position >= 21 && position <= 25:
      return {
        fontSize: isMobile ? '14px' : '16px',
        color: '#545E6B',
        fontWeight: 300,
      };
    default:
      return {
        fontSize: '12px',
        color: '#545E6B',
        fontWeight: 300,
      };
  }
};
