import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import React from 'react';
import { useStartQuestionMutation } from 'src/contexts/runLivePollSessionContext';
import useInterval from 'src/hooks/useInterval';
import { LivePollSessionHeaderForZoom } from '../session-header/LivePollSessionHeaderForZoom';
import styles from './CountDownForZoom.module.css';

export const CountDownForZoom = () => {
  const [count, setCount] = React.useState<number>(3);
  const [loading, setLoading] = React.useState<boolean>(false);
  const calledRef = React.useRef<boolean>(false);
  const startQuestionMutation = useStartQuestionMutation();

  React.useEffect(() => {
    if (count === 1) {
      if (startQuestionMutation.isIdle && !calledRef.current) {
        startQuestionMutation.mutate();
        calledRef.current = true;
      }
    }

    if (count <= 0) {
      setLoading(true);
    }
  }, [count, startQuestionMutation]);

  useInterval(
    () => {
      setCount(count - 1);
    },
    // Delay in milliseconds or null to stop it
    count <= 0 ? null : 1000,
  );

  if (loading) {
    return (
      <div className={styles.spinnerContainer}>
        <LpSpinner message="Loading" />
      </div>
    );
  }

  return (
    <div className={styles.innerContainer}>
      <LivePollSessionHeaderForZoom />

      <div className={styles.loadingSpinner}>
        <div className={styles.pulsatingRing} />
        <span data-testid="pulsating-countdown">{count}</span>
      </div>
      {/* <div className={styles.letsBeginText}>Lets begin...</div> */}
    </div>
  );
};
