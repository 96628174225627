import { RibbonSvg } from '../../svgImages/Ribbon.svg';
import styles from './LpTopThreeLeaderboard.module.css';

interface Props {
  items: { name: string; value: string }[];
  title: string;
  testIdForName: string;
  testIdForScore: string;
}

export const LpTopThreeLeaderboard = ({
  items,
  title,
  testIdForName,
  testIdForScore,
}: Props) => {
  if (!items || items.length === 0) {
    return;
  }

  return (
    <div>
      <div className={styles.topScorerText}>{title}</div>
      <div className={styles.respondentList}>
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className={
                index !== 0
                  ? styles.respondentNameWithScore
                  : styles.topRespondent
              }
            >
              <div className={styles.respondentNameAndRibbon}>
                {index === 0 && (
                  <span className={styles.ribbonImage}>
                    <RibbonSvg />
                  </span>
                )}
                <div
                  className={styles.respondentName}
                  data-testid={testIdForName}
                >
                  {item.name}
                </div>
              </div>
              <div className={styles.score} data-testid={testIdForScore}>
                {item.value}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
