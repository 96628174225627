import { LikeSvg } from '@livepolls/ui-components/src/components/svgImages/Like.svg';
import { LiveFeedPinUnpin } from '@livepolls/ui-components/src/enums/livefeed-pin-unpin.enum';
import { LiveFeedQuestionResponse } from '@livepolls/ui-components/src/interfaces/livefeed-question-response';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { UseMutationResult } from 'react-query';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './LiveFeedComment.module.css';
import { LpSpinner } from '../../../components/spinner/LpSpinner';
import { faStar } from '@fortawesome/free-regular-svg-icons';

interface Props {
  liveFeedResponse: LiveFeedQuestionResponse;
  commentPinUnpin?: () => UseMutationResult<
    void,
    unknown,
    { commentId: number; pinUnpin: LiveFeedPinUnpin },
    unknown
  >;
}

export const LiveFeedComment = ({
  liveFeedResponse,
  commentPinUnpin,
}: Props) => {
  const { id, isPinned, respondentName, text, upvoteCount } = liveFeedResponse;
  const [mutation] = useState<
    | UseMutationResult<
        void,
        unknown,
        { commentId: number; pinUnpin: LiveFeedPinUnpin },
        unknown
      >
    | undefined
  >((commentPinUnpin && commentPinUnpin()) || undefined);

  const handleCommentClick = () => {
    if (!mutation) {
      return;
    }

    mutation.mutate({
      commentId: id,
      pinUnpin:
        isPinned === LiveFeedPinUnpin.PIN
          ? LiveFeedPinUnpin.UNPIN
          : LiveFeedPinUnpin.PIN,
    });
  };

  const showBookMarkIcon = liveFeedResponse.isPinned === LiveFeedPinUnpin.PIN;

  const showLoader = mutation && mutation.isLoading;
  return (
    <div className={styles.container} onClick={handleCommentClick}>
      <div className={styles.textContainer}>
        <span className={styles.comment} data-testid="livefeed-response-text">
          {text}
        </span>
        <div className={styles.respondentName}>
          <>
            {showBookMarkIcon && (
              <span
                className={styles.bookmarkIconPinned}
                data-testid="livefeed-bookmark-pinned"
              >
                <FontAwesomeIcon
                  icon={solidStar}
                  color="#1b3380"
                  className={styles.starIcon}
                />
              </span>
            )}
            {!showBookMarkIcon && (
              <span
                className={styles.bookmarkIconUnPinned}
                data-testid="livefeed-bookmark-unpinned"
              >
                <FontAwesomeIcon
                  icon={faStar}
                  color="#545E6B"
                  className={styles.starIcon}
                />
              </span>
            )}
            {showLoader && (
              <span className={styles.bookmarkIconPinned}>
                <LpSpinner size="sm" />
              </span>
            )}
          </>
          <span data-testid="livefeed-respondent-name">{respondentName}</span>

          {upvoteCount > 0 && (
            <>
              <span className={styles.likeIcon}>
                <LikeSvg width="14px" height="14px" />
              </span>
              <span data-testid="livefeed-up-vote-count">
                {getFormattedLikeCount(upvoteCount)}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

// format number into letters
const getFormattedLikeCount = (upVoteCount: number): string => {
  const formatAfter = 1000;
  const roundUpto = 2; // decimal points

  if (upVoteCount < formatAfter) {
    return `${upVoteCount}`;
  }

  return `${(upVoteCount / formatAfter).toFixed(roundUpto)}K`;
};
