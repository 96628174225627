import { formatDateToMonthDayYearTime } from '../../utils/format-date-to-month-day-year-time';
import styles from './LpDownloadReportPopup.module.css';
import { IDownloadFile } from 'src/interfaces/download-file.interface';

const MAX_FILES_TO_DISPLAY = 5;

interface Props {
  files: IDownloadFile[];
  isError: boolean;
  removeFile(id: string): void;
  text: {
    generatingSessionReport: string;
    fileGenerationBeginShortly: string;
    sessionReportGeneratedAndDownloaded: string;
    done: string;
    reportGenerationFailed: string;
    somethingWentWrongPleaseRetry: string;
    yourReportIsOnItsWay: string;
  };
}

export const LpDownloadReportPopup = ({
  files,
  isError,
  text,
  removeFile,
}: Props) => {
  if (!files || files.length === 0) {
    return <span></span>;
  }

  const hideReportsPopup = (id: string) => {
    removeFile(id);
  };

  const hasReportCreationStarted = (file: IDownloadFile) => {
    return !!(file?.creationId > 0);
  };

  const displayErrorsToAllFiles = () => {
    return files.map(file => {
      return displayError(file);
    });
  };

  const displayAllFiles = () => {
    return files.map(file => (
      <div key={file.id}>
        {file.error && displayError(file)}
        {!file.error && displayFilePopups(file)}
      </div>
    ));
  };

  const displayFilePopups = (file: IDownloadFile) => {
    if (isFileIsInProgress(file)) {
      return displayInprogressFile(file);
    }
    return displayCompletedFile(file);
  };

  const displayInprogressFile = (file: IDownloadFile) => {
    return (
      <div className={styles.topBorderForIncomplete}>
        <div className={styles.inProgressFilesTitle}>
          {text.generatingSessionReport}
          <span
            className={`wm-close ${styles.closeReportButton}`}
            onClick={() => hideReportsPopup(file.id)}
            data-testid="close report popup"
          />
        </div>

        {hasReportCreationStarted(file) &&
          showInprogressFileDetails(file, file.progress)}
        {!hasReportCreationStarted(file) && (
          <div className={styles.fileDownloadInQueue}>
            {text.fileGenerationBeginShortly}
          </div>
        )}
      </div>
    );
  };

  const displayCompletedFile = (file: IDownloadFile) => {
    const isDownloaded = !!file.isDownloaded;

    return (
      <div className={styles.topBorder}>
        <div className={styles.doneTextContainer}>
          <div className={styles.doneTextRow}>
            <span className="wm-check-circle" />
            <span className={styles.doneText}>{text.done}</span>
          </div>
          <span
            className={`wm-close ${styles.closeReportButton}`}
            onClick={() => hideReportsPopup(file.id)}
            data-testid="close report popup"
          />
        </div>
        <div className={styles.date}>
          {formatDateToMonthDayYearTime(file.creationTime)}
        </div>

        <div className={styles.completedFilesText}>
          {isDownloaded
            ? text.sessionReportGeneratedAndDownloaded
            : text.yourReportIsOnItsWay}
        </div>
      </div>
    );
  };

  const displayError = (file: IDownloadFile) => {
    return (
      <div className={styles.topBorderForError} key={file.id}>
        <div className={styles.errorText}>
          <div className={styles.errorInfoRow}>
            <span className={`wm-info ${styles.errorInfo}`}></span>
            {text.reportGenerationFailed}
          </div>
          <span
            className={`wm-close ${styles.closeReportButton}`}
            onClick={() => hideReportsPopup(file.id)}
            data-testid="close report popup"
          />
        </div>

        <div className={styles.date}>
          {formatDateToMonthDayYearTime(file.creationTime)}
        </div>
        <span className={styles.somethingWentWrongText}>
          {text.somethingWentWrongPleaseRetry}
        </span>
      </div>
    );
  };

  return (
    <div
      className={`${styles.reportDownload} ${
        files.length >= MAX_FILES_TO_DISPLAY
          ? styles.reportDownloadContainer
          : ''
      }`}
    >
      {isError && displayErrorsToAllFiles()}
      {!isError && displayAllFiles()}
    </div>
  );
};

const showInprogressFileDetails = (file: IDownloadFile, width: number) => (
  <div className={styles.fileContainer}>
    <div className={styles.date}>
      {formatDateToMonthDayYearTime(file.creationTime)}
    </div>
    <div className={styles.fileName}>{file.name}</div>
    <div className={styles.progressBarContainer}>
      <div className={styles.progressbar} style={{ width: `${width}%` }}></div>
    </div>
  </div>
);


const isFileIsInProgress = (file: IDownloadFile) => {
  return !!(file.progress < 100);
};