import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import { LivePollType } from '@livepolls/ui-components/src/enums/livepoll-type.enum';
import { IChoiceQuestion } from '@livepolls/ui-components/src/interfaces/choice-question.interface';
import {
  useCurrentQuestion,
  useRunLivePollSessionContext,
} from 'src/contexts/runLivePollSessionContext';
import { useQuestionResult } from 'src/hooks/store-hooks';
import { ChoiceQuestionLiveChartForZoom } from '../../../choice-question-live-chart/ChoiceQuestionLiveChart';
import { LivePollSessionHeaderForZoom } from '../../../session-header/LivePollSessionHeaderForZoom';
import styles from './ChoiceQuestionChart.module.css';

interface Props {
  displayAnswer: boolean;
}

export const ChoiceQuestionFinishedScreenForZoom = ({
  displayAnswer,
}: Props) => {
  const { livePollSession } = useRunLivePollSessionContext();
  const isQuiz = livePollSession.livePollType === LivePollType.QUIZ;
  const currentQuestion = useCurrentQuestion() as IChoiceQuestion;
  const {
    isLoading,
    isError,
    error,
    data: questionResult,
  } = useQuestionResult(
    livePollSession.livePollId,
    livePollSession.id,
    currentQuestion.id,
  );

  if (isLoading) {
    return <LpSpinner />;
  }

  if (isError || error) {
    return <div>Something went wrong. Please try again</div>;
  }

  return (
    <>
      <LivePollSessionHeaderForZoom />
      <div className={styles.container}>
        <div className={styles.questionText}>
          <strong> Question: </strong>
          {currentQuestion.title}
        </div>

        {questionResult?.responses && (
          <ChoiceQuestionLiveChartForZoom
            answers={currentQuestion.answers}
            responses={questionResult?.responses}
            isQuiz={isQuiz}
            displayAnswer={displayAnswer}
          />
        )}
      </div>
    </>
  );
};
