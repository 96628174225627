import { ChartView } from 'src/enums/chart-view.enum';
import styles from './LpSwipeQuestionHeader.module.css';
import { LpSwipeQuestionDropownForQuiz } from './LpSwipeQuestionDropownForQuiz';
import { LpSwipeQuestionSortIcon } from './LpSwipeQuestionSortIcon';
import { SortOrder } from '../../../../enums/sort-order.enum';
import { SwipeQuestionChartView } from '../../../../enums/teams-chart-view.enum';
import { LpSwipeQuestionToggleForTeams } from './LpSwipeQuestionToggleForTeams';

interface Props {
  currentView: ChartView;
  order: SortOrder;
  teamsOrIndividualView: SwipeQuestionChartView;
  isTeamsEnabled: boolean;
  text: {
    sortBy: string;
    default: string;
    accuracy: string;
    teamRankings: string;
    swipeQuestionCorrectText: string;
    swipeQuestionIncorrectText: string;
    individualView: string;
    teamsView: string;
  };
  onChangeView: (view: ChartView) => void;
  onChangeOrder: (order: SortOrder) => void;
  onChangeTeamsOrIndividualView: (view: SwipeQuestionChartView) => void;
}

export const LpSwipeQuestionHeader = ({
  currentView,
  order,
  teamsOrIndividualView,
  isTeamsEnabled = false,
  text,
  onChangeOrder,
  onChangeView,
  onChangeTeamsOrIndividualView,
}: Props) => {
  const showAccuracyForTeams =
    isTeamsEnabled && teamsOrIndividualView === SwipeQuestionChartView.TEAMS;

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        {isTeamsEnabled && (
          <LpSwipeQuestionToggleForTeams
            currentView={teamsOrIndividualView}
            onChangeView={onChangeTeamsOrIndividualView}
            text={text}
          />
        )}
        <div
          className={`${styles.secondRow} ${
            isTeamsEnabled ? styles.teamsEnabledHeader : ''
          }`}
        >
          <LpSwipeQuestionDropownForQuiz
            currentView={currentView}
            onChangeView={onChangeView}
            text={text}
          />

          <LpSwipeQuestionSortIcon
            order={order}
            onChangeOrder={onChangeOrder}
          />
        </div>
      </div>

      {!showAccuracyForTeams && (
        <div className={styles.rightSide}>
          <div className={styles.rightSideLeft}>
            <div className={styles.leftBox}></div>
            {text.swipeQuestionCorrectText}
          </div>
          <div className={styles.rightSideRight}>
            <div className={styles.rightBox}></div>
            {text.swipeQuestionIncorrectText}
          </div>
        </div>
      )}
      {showAccuracyForTeams && (
        <div className={styles.rightSideForTeams}>
          <div>{text.teamRankings}</div>
        </div>
      )}
    </div>
  );
};
