import create from 'zustand';
import { io, Socket } from 'socket.io-client';
import { ADMIN_WS_CONNECTION_URL } from 'src/constants/app.constants';

export enum SocketConnectionStatus {
  DISCONNECTED = 'disconnected',
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
}

interface SocketState {
  socket: Socket | null;
  status: SocketConnectionStatus;
  connectSocket: () => void;
  disconnectSocket: () => void;
  emitFileProgress: (data: number[]) => Promise<any>;
}

export const SOCKET_EVENTS = {
  FETCH_FILES_PROGRESS: 'admin:get.downloadable-report-creation-status',
};

const handleData = (handler: (data: any) => void) => {
  return (data: any) => {
    handler(data);
  };
};

export const useSocketStore = create<SocketState>((set, get) => ({
  socket: null,
  status: SocketConnectionStatus.DISCONNECTED, // Initial state

  connectSocket: () => {
    set({ status: SocketConnectionStatus.CONNECTING });

    const socket = io(ADMIN_WS_CONNECTION_URL, {
      path: '/ws/admin',
      withCredentials: true,
    });

    socket.on('connect', () => {
      console.log('Connected');
      set({ socket, status: SocketConnectionStatus.CONNECTED });
    });

    socket.on('disconnect', () => {
      console.log('Disconnected');
      set({ socket: null, status: SocketConnectionStatus.DISCONNECTED });
    });

    socket.on('connect_error', err => {
      console.error('Connection error:', err);
      set({ status: SocketConnectionStatus.DISCONNECTED });
    });

    set({ socket });
  },

  disconnectSocket: () => {
    const { socket } = get();
    if (socket) {
      socket.disconnect();
      set({ socket: null, status: SocketConnectionStatus.DISCONNECTED });
    }
  },

  emitFileProgress: (creationIds: number[]): Promise<any> => {
    const { socket } = get();

    if (socket) {
      return new Promise((resolve, reject) => {
        const eventData = {
          reportCreationIds: creationIds,
        };

        socket.emit(
          SOCKET_EVENTS.FETCH_FILES_PROGRESS,
          eventData,
          handleData(async data => {
            resolve(data);
          }),
        );
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve({ type: 'error' });
      });
    }
  },
}));
