import { SwipeQuestionCard } from '../../../interfaces/swipe-question-card.interface';
import styles from './CardWithTitleForQuiz.module.scss';
import { useScreenResize } from '../../../utils/getScreenSize.util';
import { getFontSizeForSwipeQuestionCard } from '../../../utils/getFontSizeForSwipeQuestionCard.util';
import { isDesktopDevice } from '../../../utils/isDesktopDevice.util';
import { getAnswerText } from '../../../utils/getAnswerText.util';

interface Props {
  swipeQuestionCard: SwipeQuestionCard;
  rank: number;
  isLastRank: boolean;
}

export const CardWithTitleForQuiz = ({
  swipeQuestionCard,
  rank,
  isLastRank,
}: Props) => {
  const { title, image } = swipeQuestionCard;
  const { width } = useScreenResize();
  const isDesktopView = isDesktopDevice(width);

  const cardContainerContent =
    image?.url != null && image.url !== '' ? (
      <div
        className={`${styles.imageCardContainer} ${
          rank === 1 ? styles.greenBorder : ''
        } ${isLastRank ? styles.redBorder : ''}`}
      >
        <div className={styles.textContainer}>
          <div className={styles.textBg}></div>
          <span className={styles.imageCardRank}>#{rank}</span>
        </div>
        <img src={image?.url} alt="" className={`${styles.image}`} />
      </div>
    ) : (
      <div
        className={`${styles.textCardContainer} 
        ${rank === 1 ? styles.greenBorder : ''}
        ${isLastRank ? styles.redBorder : ''}`}
      >
        <span className={styles.textCardRank} data-testid="card rank">
          #{rank}
        </span>
      </div>
    );

  return (
    <div className={styles.mainContainer}>
      {cardContainerContent}
      <div className={styles.rightSideContainer}>
        <div
          className={styles.cardTitleContainer}
          data-testid="card title"
          style={getFontSizeForSwipeQuestionCard(title, isDesktopView)}
        >
          <span>{getAnswerText({ text: title, image: image })}</span>
        </div>
      </div>
    </div>
  );
};
