import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './fonts.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { AppProviders } from './app-providers';
import { Route, Routes } from 'react-router-dom';
import { MeetingApps } from './meetings-apps/MeetingApps';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThirdPartyAppIntegrationProvider } from './contexts/ThirdPartyAppsContext';
import './i18n/i18n';
import * as AppUrls from './components/navbar/app.urls';
import '@npm-questionpro/wick-ui-icon/dist/wu-icon.css';
import '@npm-questionpro/wick-ui-lib/dist/style.css';
import './index.css';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';

console.log('app version - 3.0');

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Suspense fallback={<LpSpinner message="Loading..." />}>
    <ThirdPartyAppIntegrationProvider>
      <Router>
        <Routes>
          <Route
            path={`${AppUrls.MEETING_APPS_URLS.LIVEPOLL_MEETING_APPS}*`}
            element={<MeetingApps />}
          />

          <Route
            path="/*"
            element={
              <AppProviders>
                <App />
              </AppProviders>
            }
          />
        </Routes>
      </Router>
    </ThirdPartyAppIntegrationProvider>
  </Suspense>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
