import { IFilterByProfileQuestionReport } from '@livepolls/ui-components/src/interfaces/filter-by-profile-question-report.interface';
import { LeaderboardRow } from '@livepolls/ui-components/src/interfaces/leaderboard-row.interface';
import { ILiveFeedQuestionResultPaginationParams } from '@livepolls/ui-components/src/interfaces/live-feed-question-result-pagination-params.interface';
import { LiveFeedQuestionResponse } from '@livepolls/ui-components/src/interfaces/livefeed-question-response';
import { ILivePollSessionReport } from '@livepolls/ui-components/src/interfaces/livepoll-session-report.interface';
import { ILivePollSession } from '@livepolls/ui-components/src/interfaces/livepoll-session.interface';
import { IPaginateDropdown } from '@livepolls/ui-components/src/interfaces/paginate-dropdown.interface';
import { RespondentAnswerStreak } from '@livepolls/ui-components/src/interfaces/respondent-answer-streak.interface';
import { RespondentRankJump } from '@livepolls/ui-components/src/interfaces/respondent-rank-jump.interface';
import { TeamLeaderboardRow } from '@livepolls/ui-components/src/interfaces/team-leaderboard-row';
import { TeamResponse } from '@livepolls/ui-components/src/interfaces/team-response.interface';
import { ITheme } from '@livepolls/ui-components/src/interfaces/theme.interface';
import { TopThreeFastestResponderInfo } from '@livepolls/ui-components/src/interfaces/top-three-responses.interface';
import { WordCloudQuestionResponse } from '@livepolls/ui-components/src/interfaces/word-cloud-question-response.interface';
import { SwipeQuestionResponse } from '@livepolls/ui-components/src/interfaces/swipe-question-response.interface';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IAddTheme } from 'src/models/add-theme.interface';
import { IChoiceQuestionAnswerCount } from 'src/models/choice-question-answer-count.interface';
import { ICustomThemes } from 'src/models/custom-themes.interface';
import { IFetchTemplatesQuestions } from 'src/models/fetch-templates-params.interface';
import { LivePollListSortBy } from 'src/models/livepoll-list-sort-by.enum';
import { IFetchLivePollsSortingParams } from 'src/models/livepoll-list-sorting-params.interface';
import { LivePollLisStarred } from 'src/models/livepoll-list-starred.enum';
import { ILivePollSessionSetting } from 'src/models/livepoll-session-setting.interface';
import {
  ILivePoll,
  ILivePollWithPermanentRoom,
} from 'src/models/livepoll.interface';
import { ILivePollsPaginate } from 'src/models/livepolls-paginate.interface';
import { LivePollsView } from 'src/models/livepolls-view.enum';
import { IPaginateQpImage } from 'src/models/paginate-qp-image.interface';
import { Paginate } from 'src/models/paginate.enum';
import { IPerformanceLogWitTotalLogsCount } from 'src/models/performance-log.interface';
import { PerformanceLogFilterEnum } from 'src/models/performance-logs-filter-by.enum';
import { IPerformanceLogsQueryParams } from 'src/models/performance-logs-query-params.interface';
import { IQpImage } from 'src/models/qp-image.interface';
import { IQpImagesResourcesUsage } from 'src/models/qp-images-resource-usage.interface';
import { IQuestionResponseTimer } from 'src/models/question-response-timer.interface';
import { ISearchTemplatesParams } from 'src/models/search-templates-params.interface';
import { ILivePollSessionStatsList } from 'src/models/session-list-pager.interface';
import { ISessionListSortingParams } from 'src/models/session-list-sorting-params.interface';
import { ISessionStatsListQueryParams } from 'src/models/session-stats-list-query-params.interface';
import { SortOrder } from 'src/models/sort-order.enum';
import { ISubmitFeedback } from 'src/models/submit-feedback.interface';
import { ISystemLogWithErrorCount } from 'src/models/system-log.interface';
import { ITemplatesQueryParams } from 'src/models/template-query-paras.interface';
import { ITemplate } from 'src/models/template.interface';
import { IUpdateTheme } from 'src/models/update-theme.interface';
import { UserSetting } from 'src/models/user-setting.interface';
import { useLivePollDesignStore } from 'src/screens/livepoll-design/store/useLivePollDesign.store';
import { IMAGES_PER_PAGE } from 'src/screens/livepoll-editor/components/question-editor/image-picker/image-picker.constants';
import {
  callApi,
  deleteApi,
  getApi,
  patchApi,
  postApi,
} from 'src/utils/api.util';
import { IAddLivePollSession } from './interfaces/add-livepoll-session.interface';
import { IAddLivePoll } from './interfaces/add-livepoll.interface';
import { ChoiceQuestionResult } from './interfaces/choice-question-result.interface';
import { IDeleteLivePoll } from './interfaces/delete-livepoll.interface';
import { LiveFeedQuestionResult } from './interfaces/livefeed-question.result.interface';
import { LivePollsQueryParams } from './interfaces/livepoll-query-params.interface';
import { IUpdateLivePollList } from './interfaces/update-livepoll-list.interface';
import { IUpdateLivePoll } from './interfaces/update-livepoll.interface';
import { IUploadImageMutation } from './interfaces/upload-image.interface';
import { IUserSettingResponse } from './interfaces/user-setting-response.interface';
import { WordCloudQuestionResult } from './interfaces/word-cloud-question-result.interface';
import { SwipeQuestionResult } from '@livepolls/ui-components/src/interfaces/swipe-question-result.interface';
import { SwipeQuestionTeamResponse } from '@livepolls/ui-components/src/interfaces/swipe-question-team-response.interface';

const fetchUserSetting = async (): Promise<UserSetting> => {
  const userSetting = (await getApi(
    `/auth/user-setting`,
  )) as IUserSettingResponse;
  return userSetting.setting;
};
const fetchLivePolls = async (
  queryParams: LivePollsQueryParams,
): Promise<ILivePollsPaginate> => {
  return await getApi(`/edit/livepolls`, { params: queryParams });
};

export const searchLivePolls = async (
  title: string | null,
): Promise<{ title: string }[]> => {
  return await getApi(`/edit/livepolls/search?title=${title}`);
};

const fetchTemplates = async (
  queryParams?: ITemplatesQueryParams,
): Promise<ITemplate[]> => {
  return await getApi(`/global/in-app-templates`, { params: queryParams });
};

const searchTemplates = async (
  searchTemplatesParams?: ISearchTemplatesParams,
): Promise<ITemplate[]> => {
  return await getApi(`/global/in-app-templates/search`, {
    params: searchTemplatesParams,
  });
};

const fetchMostPopularTemplates = async (): Promise<ITemplate[]> => {
  return await getApi(`/global/in-app-templates/most-popular`);
};

export const fetchSystemErrors = async (
  pager: IPaginateDropdown,
): Promise<ISystemLogWithErrorCount> => {
  return await getApi(
    `/log/system-log?pageIndex=${pager.pageIndex}&perPage=${pager.perPage}`,
  );
};

export const fetchPerformanceLogs = async (
  pager: IPaginateDropdown,
  filterBy: PerformanceLogFilterEnum | null,
): Promise<IPerformanceLogWitTotalLogsCount> => {
  const params = {
    pageIndex: pager.pageIndex,
    perPage: pager.perPage,
  } as IPerformanceLogsQueryParams;

  if (filterBy != null) {
    params.filterBy = filterBy;
  }

  return await getApi(`/log/performance-log`, {
    params,
  });
};

const addLivePoll = async (data: IAddLivePoll): Promise<ILivePoll> => {
  return await postApi('/edit/livepolls', data);
};

const addLivePollFromTemplate = async (
  templateId: number,
): Promise<ILivePoll> => {
  const postData = { templateId };
  return await postApi('/edit/livepolls/in-app-template', postData);
};

const addLivePollSession = (
  livePollId: number,
  data: ILivePollSessionSetting,
): Promise<ILivePollSession> => {
  return postApi(`/take/${livePollId}/livepoll-sessions`, data);
};

export const fetchLivePoll = (
  livePollId: number,
): Promise<ILivePoll | ILivePollWithPermanentRoom> => {
  return getApi(`/edit/livepolls/${livePollId}`);
};

const fetchTemplateQuestions = (
  templateId: number,
): Promise<IFetchTemplatesQuestions> => {
  return getApi(`/global/in-app-templates/templates/${templateId}`);
};

const fetchTheme = (livePollId: number): Promise<ICustomThemes> => {
  return getApi(`/edit/livepolls/${livePollId}/theme/custom/all`);
};

const fetchLivePollSessions = (
  livePollId: number,
): Promise<ILivePollSession[]> => {
  return getApi(`/take/${livePollId}/livepoll-sessions`);
};

const fetchLivePollSessionsWithStats = (
  livePollId: number,
  paginationOptions: IPaginateDropdown,
  sortingParams: ISessionListSortingParams,
  searchText: string,
): Promise<ILivePollSessionStatsList> => {
  const { pageIndex, perPage } = paginationOptions;
  const { sortBy, sortOrder } = sortingParams;

  const params = {
    pageIndex,
    perPage,
    sortBy,
    sortOrder,
  } as ISessionStatsListQueryParams;

  if (searchText && searchText.length > 0) {
    params.searchText = searchText;
  }

  return getApi(`/take/${livePollId}/livepoll-sessions/stats`, {
    params,
  });
};

const fetchLivePollSession = (
  livePollId: number,
  livePollSessionId: number,
): Promise<ILivePollSession> => {
  return getApi(`/take/${livePollId}/livepoll-sessions/${livePollSessionId}`);
};

const fetchLivePollSessionReport = (
  livePollId: number,
  livePollSessionId: number,
  pageLimit: number,
): Promise<ILivePollSessionReport> => {
  return getApi(
    `/take/${livePollId}/livepoll-sessions/${livePollSessionId}/report?pageLimit=${pageLimit}`,
  );
};

const fetchProfileQuestionFilterReport = (
  livePollId: number,
  livePollSessionId: number,
  profileQuestionId: string,
) => {
  return getApi(
    `/take/${livePollId}/livepoll-sessions/${livePollSessionId}/report/profile-questions/${profileQuestionId}/filter-report`,
  );
};

const fetchQuestionResponseTimer = (
  livePollId: number,
  livePollSessionId: number,
): Promise<IQuestionResponseTimer> => {
  return getApi(
    `/take/${livePollId}/livepoll-sessions/${livePollSessionId}/question-response-timer`,
  );
};

const fetchImages = (pageNumber: number): Promise<IPaginateQpImage> => {
  const params = {
    pageNumber,
    pageSize: IMAGES_PER_PAGE,
  };
  return getApi(`/edit/images`, { params });
};

const uploadImage = (formData: FormData): Promise<IQpImage> => {
  return postApi(`/edit/image`, formData);
};

const fetchImagesResourceUsage = (): Promise<IQpImagesResourcesUsage> => {
  return getApi(`/edit/images/resourceUsage`);
};

const submitFeedback = (response: ISubmitFeedback) => {
  return postApi('/feedback', response);
};

export const startDownloadableReportCreation = async (
  livePollSessionId: number,
) => {
  return await postApi('/downloadable-reports', { livePollSessionId });
};

export const fetchDownloadedFiles = async () => {
  return await getApi('/downloadable-reports');
};

export const downloadReport = async (reportCreationId: number) => {
  return await callApi(`/downloadable-reports/${reportCreationId}/download`, {
    method: 'GET',
    responseType: 'blob',
  });
};

export const addZoomAppInvitation = (
  livePollSessionId: number,
  invitationId: string,
  meetingId: string,
): Promise<ILivePollSession> => {
  return postApi(`/auth/zoom/zoom-app-invitation`, {
    invitationId,
    meetingId,
    livePollSessionId,
  });
};

type QuestionResult =
  | LiveFeedQuestionResult
  | ChoiceQuestionResult
  | WordCloudQuestionResult
  | SwipeQuestionResult;

export const fetchQuestionResult = (
  livePollId: number,
  livePollSessionId: number,
  questionId: string,
  liveFeedPaginationParams?: ILiveFeedQuestionResultPaginationParams,
): Promise<QuestionResult> => {
  const params =
    liveFeedPaginationParams != null
      ? { ...liveFeedPaginationParams }
      : undefined;
  return getApi(
    `/take/${livePollId}/livepoll-sessions/${livePollSessionId}/question-result/${questionId}`,
    {
      params,
    },
  );
};

export const updateLivePoll = (
  id: number,
  updates: {
    title?: string;
    starred?: LivePollLisStarred;
    enableProfileQuestions?: boolean;
  },
): Promise<void> => {
  return patchApi(`/edit/livepolls/${id}`, updates);
};

const deleteLivePoll = (livePollId: number): Promise<void> => {
  return deleteApi(`/edit/livepolls/${livePollId}`);
};

export const addTheme = (
  request: IAddTheme,
  livePollId: number,
): Promise<ITheme> => {
  const { ...data } = request;
  return postApi(`/edit/livepolls/${livePollId}/theme`, data);
};

export const updateTheme = (
  request: IUpdateTheme,
  livePollId: number,
  themeId: number,
): Promise<void> => {
  const { ...data } = request;
  return patchApi(`/edit/livepolls/${livePollId}/theme/${themeId}`, data);
};

export const addLivePollFromTemplateUid = async (
  livePollsTemplateUID: string,
): Promise<ILivePoll> => {
  const templatedUidInfo = { livePollsTemplateUID };
  return await postApi('/edit/livepolls/template', templatedUidInfo);
};

const checkIfUndefinedOrNull = (value?: number) => {
  return value == null;
};

const checkIfNull = (value?: string | null) => {
  return value == null;
};

const assignDefaultValuesIfUndefined = (
  sortingQueryParams: IFetchLivePollsSortingParams,
  livePollsViewType: LivePollsView | undefined,
  paginationParams: IPaginateDropdown,
): LivePollsQueryParams => {
  const sortBy = !checkIfUndefinedOrNull(sortingQueryParams.sortBy)
    ? sortingQueryParams.sortBy
    : LivePollListSortBy.LAST_SESSION;

  const sortOrder = !checkIfUndefinedOrNull(sortingQueryParams.sortOrder)
    ? sortingQueryParams.sortOrder
    : SortOrder.DESC;

  const viewType = (
    !checkIfUndefinedOrNull(livePollsViewType)
      ? livePollsViewType
      : LivePollsView.LIST
  )!;

  const pageIndex = !checkIfUndefinedOrNull(paginationParams.pageIndex)
    ? paginationParams.pageIndex
    : Paginate.PAGE_INDEX;
  const perPage = !checkIfUndefinedOrNull(paginationParams.perPage)
    ? paginationParams.perPage
    : Paginate.PER_PAGE;

  const title = !checkIfNull(sortingQueryParams.title)
    ? sortingQueryParams.title
    : null;

  return {
    sortBy,
    sortOrder,
    viewType,
    pageIndex,
    perPage,
    title,
  };
};

export function useLivePolls(
  sortingQueryParams: IFetchLivePollsSortingParams,
  livePollsViewType: LivePollsView,
  paginationParams: IPaginateDropdown,
) {
  let queryParams = assignDefaultValuesIfUndefined(
    sortingQueryParams,
    livePollsViewType,
    paginationParams,
  );
  return useQuery<ILivePollsPaginate, Error>(['livepolls', queryParams], () =>
    fetchLivePolls(queryParams!),
  );
}

export function useTemplates(params: ITemplatesQueryParams) {
  return useQuery<ITemplate[], Error>(['templates', params], () =>
    fetchTemplates(params),
  );
}

export function useMostPopularTemplates() {
  return useQuery<ITemplate[], Error>(['most-popular-templates'], () =>
    fetchMostPopularTemplates(),
  );
}

export function useImages(pageNumber: number) {
  const queryParams = {
    pageNumber,
  };
  return useQuery<IPaginateQpImage, Error>(
    ['images', queryParams],
    () => fetchImages(pageNumber),
    { refetchOnWindowFocus: false },
  );
}

export function useImagesResourceUsage() {
  return useQuery<IQpImagesResourcesUsage, Error>(
    'images-resource-usage',
    () => fetchImagesResourceUsage(),
    { refetchOnWindowFocus: false },
  );
}

export function useUserSetting() {
  return useQuery<UserSetting, Error>(['user-setting'], () =>
    fetchUserSetting(),
  );
}

export function useLivePoll(
  livePollId: number,
  onSuccessCallback?: () => (data: ILivePoll) => void,
) {
  return useQuery<ILivePoll | ILivePollWithPermanentRoom, Error>(
    `livepoll-${livePollId}`,
    () => fetchLivePoll(livePollId),
    {
      onSuccess: onSuccessCallback && onSuccessCallback(),
    },
  );
}

export function useTemplateQuestions(templateId: number) {
  return useQuery<IFetchTemplatesQuestions, Error>(
    [`templates-questions-${templateId}`],
    () => fetchTemplateQuestions(templateId),
  );
}

export function useAllCustomThemes(livePollId: number) {
  return useQuery<ICustomThemes, Error>(
    `livepoll-${livePollId}-theme-custom`,
    () => fetchTheme(livePollId),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data: ICustomThemes) => {
        useLivePollDesignStore.getState().setTheme(data);
      },
    },
  );
}

export const deleteTheme = (
  livePollId: number,
  themeIds: number[],
): Promise<void> => {
  return deleteApi(`/edit/livepolls/${livePollId}/theme`, themeIds);
};

export function useQuestionResponseTimer(
  livePollId: number,
  livePollSessionId: number,
  questionResponseTimerId: number,
) {
  return useQuery<IQuestionResponseTimer, Error>(
    `question-response-timer-${questionResponseTimerId}`,
    () => fetchQuestionResponseTimer(livePollId, livePollSessionId),
    {
      enabled: questionResponseTimerId > 0,
      refetchOnWindowFocus: false,
    },
  );
}

// NOTE: this is for ChoiceQuestion
export function useQuestionResult(
  livePollId: number,
  livePollSessionId: number,
  questionId: string,
  refetchTimerValue?: number,
) {
  return useQuery<
    {
      questionId: string;
      responses: IChoiceQuestionAnswerCount[];
      leaderboard: { leaderboardRows: LeaderboardRow[] };
      responseCount: number;
      teamLeaderboard?: TeamLeaderboardRow[];
      responsesByTeam?: TeamResponse[];
      topThreeFastestResponderInfo: TopThreeFastestResponderInfo[];
      respondentAnswerStreak: RespondentAnswerStreak[];
      respondentRankJump: RespondentRankJump[];
    },
    Error
  >(
    `question-result-${questionId}`,
    async () => {
      const {
        responses,
        leaderboardRows,
        responseCount,
        teamLeaderboardRows,
        responsesByTeam,
        topThreeFastestResponderInfo,
        respondentAnswerStreak,
        respondentRankJump,
      } = (await fetchQuestionResult(
        livePollId,
        livePollSessionId,
        questionId,
      )) as ChoiceQuestionResult;

      return {
        questionId,
        responses,
        leaderboard: { leaderboardRows },
        responseCount,
        responsesByTeam,
        teamLeaderboard: teamLeaderboardRows,
        topThreeFastestResponderInfo: topThreeFastestResponderInfo,
        respondentAnswerStreak: respondentAnswerStreak,
        respondentRankJump: respondentRankJump,
      };
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: refetchTimerValue != null ? refetchTimerValue : false,
    },
  );
}

export function useGetAllLiveFeedQuestionResult(
  livePollId: number,
  livePollSessionId: number,
  questionId: string,
) {
  return useQuery<
    {
      questionId: string;
      liveFeedResponses: LiveFeedQuestionResponse[];
      responseCount: number;
      responseCountForLiveFeedView: number;
    },
    Error
  >(
    `livefeed-question-result-${questionId}`,
    async () => {
      const { liveFeedResponses, responseCount, responseCountForLiveFeedView } =
        (await fetchQuestionResult(
          livePollId,
          livePollSessionId,
          questionId,
        )) as LiveFeedQuestionResult;
      return {
        questionId,
        liveFeedResponses,
        responseCount,
        responseCountForLiveFeedView,
      };
    },
    { refetchOnWindowFocus: false },
  );
}

export function usePaginatedLiveFeedQuestionResult(
  livePollId: number,
  livePollSessionId: number,
  questionId: string,
  liveFeedPaginationParams: ILiveFeedQuestionResultPaginationParams,
  refetchTimerValue?: number,
) {
  return useQuery<
    {
      questionId: string;
      liveFeedResponses: LiveFeedQuestionResponse[];
      responseCount: number;
      responseCountForLiveFeedView: number;
    },
    Error
  >(
    [`livefeed-question-result-${questionId}`, liveFeedPaginationParams],
    async () => {
      const { liveFeedResponses, responseCount, responseCountForLiveFeedView } =
        (await fetchQuestionResult(
          livePollId,
          livePollSessionId,
          questionId,
          liveFeedPaginationParams,
        )) as LiveFeedQuestionResult;
      return {
        questionId,
        liveFeedResponses,
        responseCount,
        responseCountForLiveFeedView,
      };
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: refetchTimerValue != null ? refetchTimerValue : false,
    },
  );
}

export function useWordCloudQuestionResult(
  livePollId: number,
  livePollSessionId: number,
  questionId: string,
  refetchTimerValue?: number,
) {
  return useQuery<
    {
      questionId: string;
      wordCloudResponses: WordCloudQuestionResponse[];
    },
    Error
  >(
    `word-cloud-question-result-${questionId}`,
    async () => {
      const { wordCloudResponses } = (await fetchQuestionResult(
        livePollId,
        livePollSessionId,
        questionId,
      )) as WordCloudQuestionResult;
      return { questionId, wordCloudResponses };
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: refetchTimerValue != null ? refetchTimerValue : false,
    },
  );
}

export function useSwipeQuestionResult(
  livePollId: number,
  livePollSessionId: number,
  questionId: string,
  refetchTimerValue?: number,
) {
  return useQuery<
    {
      swipeQuestionResponses: SwipeQuestionResponse[];
      responseCount?: number;
      leaderboard?: { leaderboardRows?: LeaderboardRow[] };
      teamLeaderboardRows?: TeamLeaderboardRow[];
      swipeQuestionResponsesByTeam?: SwipeQuestionTeamResponse[];
    },
    Error
  >(
    `swipe-question-result-${questionId}`,
    async () => {
      const {
        swipeQuestionResponses,
        leaderboardRows,
        responseCount,
        teamLeaderboardRows,
        swipeQuestionResponsesByTeam,
      } = (await fetchQuestionResult(
        livePollId,
        livePollSessionId,
        questionId,
      )) as SwipeQuestionResult;
      return {
        swipeQuestionResponses,
        leaderboard: { leaderboardRows },
        responseCount,
        teamLeaderboardRows,
        swipeQuestionResponsesByTeam,
      };
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: refetchTimerValue != null ? refetchTimerValue : false,
    },
  );
}

export function useLivePollSession(
  livePollId: number,
  livePollSessionId: number,
) {
  return useQuery<ILivePollSession, Error>(
    `livepoll-session-${livePollSessionId}`,
    () => fetchLivePollSession(livePollId, livePollSessionId),
    { refetchOnWindowFocus: false },
  );
}

export function useLivePollSessions(livePollId: number) {
  return useQuery<ILivePollSession[], Error>(
    `livepoll-sessions-${livePollId}`,
    () => fetchLivePollSessions(livePollId),
  );
}

export function useLivePollSessionsWithStats(
  livePollId: number,
  paginationOptions: IPaginateDropdown,
  sortingParams: ISessionListSortingParams,
  searchText: string,
) {
  const { pageIndex, perPage } = paginationOptions;
  const { sortBy, sortOrder } = sortingParams;

  return useQuery<ILivePollSessionStatsList, Error>(
    [
      'livepoll-sessions-stats',
      livePollId,
      { pageIndex, perPage, sortBy, sortOrder, searchText },
    ],
    () =>
      fetchLivePollSessionsWithStats(
        livePollId,
        { pageIndex, perPage },
        { sortBy, sortOrder },
        searchText,
      ),
  );
}

export function useLivePollSessionReport(
  livePollId: number,
  livePollSessionId: number,
  pageLimit: number,
) {
  return useQuery<ILivePollSessionReport, Error>(
    `livepoll-session-report-${livePollSessionId}`,
    () => fetchLivePollSessionReport(livePollId, livePollSessionId, pageLimit),
  );
}

export function useProfileQuestionFilterReport(
  livePollId: number,
  livePollSessionId: number,
  profileQuestionId: string,
) {
  return useQuery<IFilterByProfileQuestionReport[], Error>(
    `livepoll-session-${livePollSessionId}-profile-question-filter-report-${profileQuestionId}`,
    () =>
      fetchProfileQuestionFilterReport(
        livePollId,
        livePollSessionId,
        profileQuestionId,
      ),
  );
}

export const useAddLivePollMutation = () => {
  return useMutation<ILivePoll, Error, IAddLivePoll, ILivePoll>(
    (data: IAddLivePoll) => {
      return addLivePoll(data);
    },
  );
};

export const useSubmitFeedBack = () => {
  return useMutation<ISubmitFeedback, Error, ISubmitFeedback>(
    (data: ISubmitFeedback) => {
      return submitFeedback(data);
    },
  );
};

export const useAddLivePollFromTemplate = () => {
  return useMutation<ILivePoll, Error, number, ILivePoll>(
    (templateId: number) => {
      return addLivePollFromTemplate(templateId);
    },
  );
};

export const useAddLivePollSessionMutation = () => {
  return useMutation<
    ILivePollSession,
    Error,
    IAddLivePollSession,
    ILivePollSession
  >((updates: IAddLivePollSession) => {
    const { data, livePollId } = updates;
    return addLivePollSession(livePollId, data);
  });
};

export const useSearchTemplatesMutation = () => {
  return useMutation<ITemplate[], Error, ISearchTemplatesParams>(
    (params: ISearchTemplatesParams) => {
      return searchTemplates(params);
    },
  );
};

export const useUploadImageMutation = () => {
  return useMutation<IQpImage, Error, IUploadImageMutation>(
    (updates: IUploadImageMutation) => {
      const { file } = updates;
      const formData = new FormData();
      formData.append('file', file);
      return uploadImage(formData);
    },
  );
};

export const useUpdateLivePollMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IUpdateLivePoll, ILivePoll>(
    (updates: IUpdateLivePoll) => {
      const { title, livePollId } = updates;
      return updateLivePoll(livePollId, { title });
    },
    {
      // Optimistically update the cache value on mutate, but store
      // the old value and return it so that it's accessible in case of
      // an error
      onMutate: async updates => {
        const { livePollId } = updates;
        await queryClient.cancelQueries(`livepoll-${livePollId}`);

        const previousValue = queryClient.getQueryData<ILivePoll>(
          `livepoll-${livePollId}`,
        );

        queryClient.setQueryData<ILivePoll>(
          `livepoll-${livePollId}`,
          (old?: ILivePoll) => {
            return {
              ...old!,
              ...updates,
            };
          },
        );

        return previousValue;
      },
      // On failure, roll back to the previous value
      onError: (err, { livePollId }, previousValue?: ILivePoll) => {
        previousValue &&
          queryClient.setQueryData<ILivePoll>(
            `livepoll-${livePollId}`,
            previousValue,
          );
      },
      // After success or failure, refetch the todos query
      onSettled: (data, error, { livePollId }) => {
        if (!error) queryClient.invalidateQueries(`livepoll-${livePollId}`);
      },
    },
  );
};

const separateLivePollsQueryParams = (
  livePollsQueryParams: LivePollsQueryParams,
): {
  sortingParams: IFetchLivePollsSortingParams;
  paginationParams: IPaginateDropdown;
  viewType: LivePollsView | undefined;
} => {
  const { sortBy, sortOrder, viewType, pageIndex, perPage } =
    livePollsQueryParams;
  const sortingParams = {
    sortBy,
    sortOrder,
  };
  const paginationParams = {
    pageIndex,
    perPage,
  };
  return {
    sortingParams,
    paginationParams,
    viewType,
  };
};

export const useDeleteLivePollMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IDeleteLivePoll, ILivePollsPaginate>(
    (request: IDeleteLivePoll) => {
      return deleteLivePoll(request.livePollId);
    },
    {
      onSuccess: (data, { livePollId, livePollsQueryParams }) => {
        const { sortingParams, viewType, paginationParams } =
          separateLivePollsQueryParams(livePollsQueryParams);
        const queryParams = assignDefaultValuesIfUndefined(
          sortingParams,
          viewType,
          paginationParams,
        );

        // recent livepolls
        const previousRecentLivePolls =
          queryClient.getQueryData<ILivePoll[]>('recent-n-livepolls');
        queryClient.setQueryData(
          'recent-n-livepolls',
          previousRecentLivePolls &&
            previousRecentLivePolls.filter(
              livePoll => livePoll.id !== livePollId,
            ),
        );
        // livepoll list
        // update the list we are currently on instantly
        const previousValue = queryClient.getQueryData<ILivePollsPaginate>([
          'livepolls',
          queryParams,
        ]);

        if (previousValue) {
          const { totalItems, livePolls } = previousValue;
          queryClient.setQueryData(['livepolls', queryParams], {
            totalItems,
            livePolls: livePolls.filter(livePoll => livePoll.id !== livePollId),
          });
        }
        // invalidate all the lists
        queryClient.invalidateQueries({
          queryKey: ['livepolls'],
        });

        queryClient.invalidateQueries('recent-n-livepolls');
      },
    },
  );
};

const updateLivePollList = (
  livePolls: ILivePoll[],
  livePollId: number,
  updates: { starred: LivePollLisStarred },
): ILivePoll[] => {
  const livePollToUpdateIndex = livePolls.findIndex(
    livePoll => livePoll.id === livePollId,
  );

  if (livePollToUpdateIndex === -1) {
    return livePolls;
  }

  const updateLivePoll = {
    ...livePolls[livePollToUpdateIndex],
    ...updates,
  };

  return [
    ...livePolls.slice(0, livePollToUpdateIndex),
    updateLivePoll,
    ...livePolls.slice(livePollToUpdateIndex + 1),
  ];
};

export const useUpdateLivePollListMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IUpdateLivePollList, ILivePollsPaginate>(
    (updates: IUpdateLivePollList) => {
      const { livePollId, starred } = updates;
      return updateLivePoll(livePollId, { starred });
    },
    {
      // Optimistically update the cache value on mutate, but store
      // the old value and return it so that it's accessible in case of
      // an error
      onMutate: async updates => {
        const { livePollId, starred, livePollsQueryParams } = updates;
        const { sortingParams, viewType, paginationParams } =
          separateLivePollsQueryParams(livePollsQueryParams);
        const queryParams = assignDefaultValuesIfUndefined(
          sortingParams,
          viewType,
          paginationParams,
        );

        await queryClient.cancelQueries(['livepolls', queryParams]);
        const previousValue = queryClient.getQueryData<ILivePollsPaginate>([
          'livepolls',
          queryParams,
        ]);

        if (previousValue) {
          const { totalItems, livePolls } = previousValue;
          queryClient.setQueryData(['livepolls', queryParams], {
            totalItems,
            livePolls: updateLivePollList(livePolls, livePollId, {
              starred,
            }),
          });
        }
        // invalidate all the lists, but don't refetch the active one
        queryClient.invalidateQueries({
          queryKey: ['livepolls'],
          refetchActive: false,
        });

        return { ...previousValue! };
      },
      // On failure, roll back to the previous value
      onError: (
        err,
        { livePollsQueryParams },
        previousValue?: ILivePollsPaginate,
      ) => {
        const { sortingParams, viewType, paginationParams } =
          separateLivePollsQueryParams(livePollsQueryParams);
        const queryParams = assignDefaultValuesIfUndefined(
          sortingParams,
          viewType,
          paginationParams,
        );
        queryClient.setQueryData(
          ['livepolls', queryParams],
          previousValue && { ...previousValue },
        );
      },
    },
  );
};
