import { IAnswer } from '@livepolls/ui-components/src/interfaces/answer.interface';
import { IChoiceQuestionAnswerCount } from 'src/models/choice-question-answer-count.interface';
import styles from './ChoiceQuestionLiveChart.module.css';

interface Props {
  answers: IAnswer[];
  responses: IChoiceQuestionAnswerCount[];
  displayAnswer?: boolean;
  isQuiz?: boolean;
}

export const ChoiceQuestionLiveChartForZoom = ({
  answers,
  responses,
  displayAnswer,
  isQuiz,
}: Props) => {
  const isCorrectAnswer = (answer: IAnswer) => {
    return answer.isCorrect && displayAnswer && isQuiz;
  };

  const getAnswerTextStyles = (answer: IAnswer) => {
    if (isCorrectAnswer(answer)) {
      return { color: '#1b3380', fontWeight: '500' };
    }
  };

  const getBarWidth = (
    answerId: string,
    responses?: IChoiceQuestionAnswerCount[],
  ): string => {
    if (!responses) {
      return '0%';
    }

    const responseForAnswer = responses.find(res => res.answerId === answerId);
    const validResponse = responses.filter(item => item.answerId !== 'NA');
    const total = validResponse.reduce((sum, item) => sum + item.count, 0);

    if (!responses || !responseForAnswer || total === 0) {
      return '0%';
    }

    return `${Math.round((responseForAnswer.count / total) * 100)}%`;
  };

  const getBarColor = (answer: IAnswer) => {
    if (displayAnswer && isCorrectAnswer(answer)) {
      return '#1b3380';
    }
  };

  const getPercentageStyles = (answer: IAnswer) => {
    if (displayAnswer && isCorrectAnswer(answer)) {
      return { color: '#1b3380', fontWeight: 500 };
    }
  };

  const getBarStyles = (
    answerId: string,
    responses?: IChoiceQuestionAnswerCount[],
  ) => {
    const width = getBarWidth(answerId, responses);
    const backgroundColor = getBarColor(
      answers.find(ans => ans.id === answerId)!,
    );
    return { width, backgroundColor };
  };

  return (
    <div className={styles.answers}>
      {answers.map(answer => {
        return (
          <div key={answer.id}>
            <div className={styles.answer} style={getAnswerTextStyles(answer)}>
              {answer.text}
            </div>
            <div className={styles.barContainer}>
              <div
                className={styles.bar}
                style={getBarStyles(answer.id, responses)}
              ></div>
              <div className={styles.percentageRowContainer}>
                <div style={getPercentageStyles(answer)}>
                  {getBarWidth(answer.id, responses)}
                </div>
                {displayAnswer && isCorrectAnswer(answer) && (
                  <div>
                    <span
                      className={`wm-check-circle ${styles.correctIcon}`}
                    ></span>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
