import { Header } from '../header/header';
import styles from './HeaderByAppType.module.css';
import logo from '../../images/questionpro-logo-nw.svg';

export const HeaderByAppType = () => {
  const isZoomPage = window.location.pathname.startsWith('/zoom');
  if (isZoomPage) {
    return (
      <div className={styles.logoContainer}>
        <img src={logo} alt="QuestionPro logo" className={styles.logo} />
      </div>
    );
  }
  return <Header />;
};
