import { LpDownloadReportPopup } from '@livepolls/ui-components/src/components/download-report-popup/LpDownloadReportPopup';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { downloadReport, fetchDownloadedFiles } from 'src/hooks/store-hooks';
import {
  downloadedFilesSelector,
  removeFileSelector,
  setDownloadedFilesSelector,
  updateErrorSelector,
  updateIsDownloadedSelector,
  updateProgressSelector,
} from 'src/screens/livepoll-design/store/DownloadReportStore.selector';
import { useDownloadReportStore } from 'src/screens/livepoll-design/store/useDownloadReport.store';
import { SocketConnectionStatus, useSocketStore } from './socketStore';
import { IDownloadFile } from '@livepolls/ui-components/src/interfaces/download-file.interface';

const DEFAULT_FILENAME = 'report.csv';
const TIMEOUT = 200;

export const DownloadRawReport = () => {
  const { emitFileProgress, connectSocket, disconnectSocket, status } =
    useSocketStore();

  const downloadedFiles = useDownloadReportStore(downloadedFilesSelector);
  const setDownloadedFiles = useDownloadReportStore(setDownloadedFilesSelector);
  const removeFile = useDownloadReportStore(removeFileSelector);
  const updateProgressToFiles = useDownloadReportStore(updateProgressSelector);
  const updateError = useDownloadReportStore(updateErrorSelector);
  const updateIsDownloaded = useDownloadReportStore(updateIsDownloadedSelector);
  const [isError, setIsError] = useState<boolean>(false);

  const inProgressCreationIds = downloadedFiles
    .filter(({ creationId, progress }) => creationId > 0 && progress < 100)
    .map(({ creationId }) => creationId);

  useEffect(() => {
    const canConnectSocket =
      inProgressCreationIds.length > 0 &&
      status === SocketConnectionStatus.DISCONNECTED &&
      !isError;

    const canDisconnectSocket =
      (inProgressCreationIds.length === 0 &&
        status === SocketConnectionStatus.CONNECTED) ||
      isError;

    if (canConnectSocket) {
      connectSocket();
    } else if (canDisconnectSocket) {
      disconnectSocket();
    }
  }, [connectSocket, disconnectSocket, isError, inProgressCreationIds, status]);

  useEffect(() => {
    if (inProgressCreationIds.length === 0) return;

    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    const fetchFileProgressAndUpdateInStore = async (ids: number[]) => {
      const response = await emitFileProgress(ids);
      if (response.type === 'error') {
        setIsError(true);
        return;
      }
      updateProgressToFiles(response);
    };

    if (status === SocketConnectionStatus.CONNECTED) {
      timeoutId = setInterval(() => {
        fetchFileProgressAndUpdateInStore(inProgressCreationIds);
      }, TIMEOUT);
    }

    return () => {
      timeoutId && clearInterval(timeoutId);
    };
  }, [inProgressCreationIds, emitFileProgress, status, updateProgressToFiles]);

  const fetchAllDownloadedFiles = useCallback(async () => {
    try {
      const files = await fetchDownloadedFiles();
      setDownloadedFiles(files);
    } catch (err) {
      setIsError(true);
    }
  }, [setDownloadedFiles]);

  useEffect(() => {
    fetchAllDownloadedFiles();
  }, [fetchAllDownloadedFiles]);

  useEffect(() => {
    const downloadCompletedReports = async () => {
      const completedCreationIdAndUniqueIds = downloadedFiles
        .filter(isFileDownloadable)
        .map(({ creationId, id, name }) => ({ creationId, id, name }));

      if (completedCreationIdAndUniqueIds.length > 0) {
        for (const {
          id,
          creationId,
          name,
        } of completedCreationIdAndUniqueIds) {
          try {
            const response = await downloadReport(creationId);
            updateIsDownloaded(id);
            const fileName = getFileName(name);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          } catch (err) {
            updateError(id, err as unknown as Error);
          }
        }
      }
    };

    downloadCompletedReports();
  }, [downloadedFiles, updateError, updateIsDownloaded]);

  return (
    <LpDownloadReportPopup
      text={{
        generatingSessionReport: t('generatingSessionReport'),
        fileGenerationBeginShortly: t('fileGenerationBeginShortly'),
        sessionReportGeneratedAndDownloaded: t(
          'sessionReportGeneratedAndDownloaded',
        ),
        done: t('done'),
        reportGenerationFailed: t('reportGenerationFailed'),
        somethingWentWrongPleaseRetry: t('somethingWentWrongPleaseRetry'),
        yourReportIsOnItsWay: t('yourReportIsOnItsWay'),
      }}
      files={downloadedFiles}
      removeFile={removeFile}
      isError={isError}
    />
  );
};

const getFileName = (fileName: string): string => {
  if (!fileName) {
    return DEFAULT_FILENAME;
  }
  return fileName;
};

const isFileDownloadable = ({
  creationId,
  progress,
  error,
  isDownloaded,
}: IDownloadFile) =>
  creationId > 0 && progress === 100 && !error && !isDownloaded;
