import React, { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Socket } from 'socket.io-client';
import { useQuestionResponseTimer } from 'src/hooks/store-hooks';
import { LeaderboardRow } from '@livepolls/ui-components/src/interfaces/leaderboard-row.interface';
import { LiveFeedPinUnpin } from '@livepolls/ui-components/src/enums/livefeed-pin-unpin.enum';
import { LiveFeedQuestionResponse } from '@livepolls/ui-components/src/interfaces/livefeed-question-response';
import { LivePollSessionState } from '@livepolls/ui-components/src/enums/livepoll-session-state.enum';
import { ILivePollSession } from '@livepolls/ui-components/src/interfaces/livepoll-session.interface';
import { ILivePoll } from 'src/models/livepoll.interface';
import { IQuestionResponseTimer } from 'src/models/question-response-timer.interface';
import { QuestionType } from '@livepolls/ui-components/src/enums/question-type';
import { QuestionResponse } from 'src/models/QuestionResponse';
import { IRespondent } from 'src/models/respondent.interface';
import { WordCloudQuestionResponse } from '@livepolls/ui-components/src/interfaces/word-cloud-question-response.interface';
import { IWordGameLeaderBoard } from 'src/models/word-game-leaderboard';
import { isLivePollSessionClosed } from 'src/utils/LivePollSessionState.util';
import { useHandleSocketError, useSocket } from './socketContext';
import { IChoiceQuestionAnswerCount } from 'src/models/choice-question-answer-count.interface';
import { getDefaultValuesForLiveFeedResultPagination } from 'src/utils/livefeed-question-result-pagination.utils';
import { ILiveFeedQuestionResultPaginationParams } from '@livepolls/ui-components/src/interfaces/live-feed-question-result-pagination-params.interface';
import { TopThreeFastestResponderInfo } from '@livepolls/ui-components/src/interfaces/top-three-responses.interface';
import { RespondentAnswerStreak } from '@livepolls/ui-components/src/interfaces/respondent-answer-streak.interface';
import { RespondentRankJump } from '@livepolls/ui-components/src/interfaces/respondent-rank-jump.interface';
import { SwipeQuestionCardSide } from 'src/models/swipe-question-card-side.enum';
import { SwipeQuestionResponse } from '@livepolls/ui-components/src/interfaces/swipe-question-response.interface';

export const EVENT_FINISH_QUESTION = 'admin:finish.question';
export const EVENT_START_QUESTION = 'admin:start.question';
export const EVENT_START_QUESTION_COUNTDOWN = 'admin:start.question.countdown';
export const EVENT_FINISH_LIVEPOLL_SESSION = 'admin:finish.livepoll-session';
export const EVENT_TERMINATE_LIVEPOLL_SESSION =
  'admin:terminate.livepoll-session';
export const EVENT_RESPONDENT_JOINED = 'admin:respondent.joined';
export const EVENT_RESPONDENT_LEFT = 'admin:respondent.left';
export const EVENT_QUESTION_ANSWERED = 'admin:question.answered';
export const EVENT_LIVEFEED_QUESTION_ANSWERED =
  'admin:livefeed-question.answered';
export const EVENT_SWIPE_QUESTION_ANSWERED = 'admin:swipe-question.answered';
export const EVENT_WORD_CLOUD_QUESTION_ANSWERED =
  'admin:word-cloud-question.answered';
export const EVENT_LAST_PROFILE_QUESTION_ANSWERED =
  'admin:last.profile-question.answered';
export const EVENT_LIVEFEED_COMMENT_VOTE = 'admin:livefeed-comment.vote';
export const EVENT_LIVEFEED_COMMENT_PIN_UNPIN =
  'admin:livefeed-comment.pin.unpin';
export const EVENT_WORD_GAME_UPDATE_SCORE = 'admin:word-game.update.score';
export const EVENT_GET_WORD_GAME_LEADERBOARD =
  'admin:get.word-game.leaderboard';
export const EVENT_GET_RESPONDENT_INFO = 'admin:get.respondent.info';
export const EVENT_CHANGE_RESPONDENT_TEAM_UID =
  'admin:change.respondent.team.uid';
export const EVENT_RECEIVED_RESPONDENT_TEAM_UID =
  'admin:received.respondent.team.uid';

const emitFinishQuestionEvent = (
  socket: Socket,
  livePollSessionId: number,
  questionId: string,
  onSuccess: (data: {
    livePollSession: ILivePollSession;
    questionResponseTimer: IQuestionResponseTimer;
    leaderboardRows?: LeaderboardRow[];
    responses?: QuestionResponse[];
    liveFeedResponses?: LiveFeedQuestionResponse[];
    wordCloudResponse?: WordCloudQuestionResponse[];
    topThreeFastestResponderInfo?: TopThreeFastestResponderInfo[];
    respondentAnswerStreak: RespondentAnswerStreak[];
    respondentRankJump: RespondentRankJump[];
  }) => void,
  liveFeedPaginationParams?: ILiveFeedQuestionResultPaginationParams,
) => {
  socket.emit(
    EVENT_FINISH_QUESTION,
    {
      livePollSessionId,
      questionId,
      liveFeedPaginationParams,
    },
    onSuccess,
  );
};

const emitStartQuestionEvent = (
  socket: Socket,
  questionId: string,
  livePollSessionId: number,
  onSuccess: (data: {
    livePollSession: ILivePollSession;
    questionResponseTimer: IQuestionResponseTimer;
  }) => void,
) => {
  socket.emit(
    EVENT_START_QUESTION,
    {
      livePollSessionId,
      questionId,
    },
    onSuccess,
  );
};

const emitStartQuestionCountDownEvent = (
  socket: Socket,
  questionId: string,
  livePollSessionId: number,
  onSuccess: (data: any) => void,
) => {
  socket.emit(
    EVENT_START_QUESTION_COUNTDOWN,
    {
      livePollSessionId,
      questionId,
    },
    onSuccess,
  );
};

const emitFinishLivePollSessionEvent = (
  socket: Socket,
  livePollSessionId: number,
  onSuccess: (data: { livePollSession: ILivePollSession }) => void,
) => {
  socket.emit(
    EVENT_FINISH_LIVEPOLL_SESSION,
    {
      livePollSessionId,
    },
    onSuccess,
  );
};

const emitTerminateLivePollSessionEvent = (
  socket: Socket,
  livePollSessionId: number,
  onSuccess: (data: { livePollSession: ILivePollSession }) => void,
) => {
  socket.emit(
    EVENT_TERMINATE_LIVEPOLL_SESSION,
    {
      livePollSessionId,
    },
    onSuccess,
  );
};

const emitPinLiveFeedComment = (
  socket: Socket,
  commentId: number,
  pinUnpin: LiveFeedPinUnpin,
  onSuccess: (rowsAffectedCount: number) => void,
) => {
  socket.emit(
    EVENT_LIVEFEED_COMMENT_PIN_UNPIN,
    {
      commentId,
      pinUnpin,
    },
    onSuccess,
  );
};

const emitChangeRespondentTeamUid = (
  socket: Socket,
  livePollSessionId: number,
  respondentId: number,
  teamUid: string,
  onSuccess: (rowsAffectedCount: number) => void,
) => {
  socket.emit(
    EVENT_CHANGE_RESPONDENT_TEAM_UID,
    { livePollSessionId, respondentId, teamUid },
    onSuccess,
  );
};

interface RunLivePollSessionContextValue {
  livePoll: ILivePoll;
  livePollSession: ILivePollSession;
  questionResponseTimer?: IQuestionResponseTimer;
  showQuestionCountdown: {
    show: boolean;
    questionTitle?: string;
  };
  respondents: IRespondent[];
  handleStartCountdown(): void;
  setShowQuestionCountdown(data: {
    show: boolean;
    questionTitle?: string;
  }): void;
  wordGameLeaderBoard: IWordGameLeaderBoard[];
  presenterNotesWindow: Window | null;
}

interface Props {
  children: React.ReactNode;
  livePoll: ILivePoll;
  livePollSession: ILivePollSession;
  presenterNotesWindow: Window | null;
}

const getNextQuestion = (
  livePoll: ILivePoll,
  livePollSession: ILivePollSession,
  questionResponseTimer?: IQuestionResponseTimer,
) => {
  if (isLivePollSessionClosed(livePollSession.state)) {
    throw new Error(`LivePollSession is already finished.`);
  }

  const questions = livePoll.questions;
  if (!questions) {
    throw new Error(`livePoll.questions is undefined`);
  }

  if (livePollSession.state === LivePollSessionState.WAITING_ROOM) {
    return questions[0];
  }

  if (!questionResponseTimer) {
    console.log(`livePollSession.state: ${livePollSession.state}`);
    throw new Error('questionResponseTimer is undefined');
  }

  const currentQuestionId = questionResponseTimer.questionId;

  const currentQuestionIndex = questions.findIndex(
    q => q.id === currentQuestionId,
  );

  if (currentQuestionIndex < 0) {
    throw new Error(
      `currentQuestionId is invalid (not found: ${currentQuestionId})`,
    );
  }

  if (currentQuestionIndex + 1 >= questions.length) {
    throw new Error(`Cannot go to next question as this is the last question`);
  }

  return questions[currentQuestionIndex + 1];
};

const RunLivePollSessionContext = React.createContext<
  RunLivePollSessionContextValue | undefined
>(undefined);

export const RunLivePollSessionContextProvider = ({
  livePoll,
  livePollSession,
  children,
  presenterNotesWindow,
}: Props) => {
  const { data: questionResponseTimer } = useQuestionResponseTimer(
    livePoll.id,
    livePollSession.id,
    livePollSession.questionResponseTimerId,
  );

  const [showQuestionCountdown, setShowQuestionCountdown] = React.useState<{
    show: boolean;
    questionTitle?: string;
  }>({ show: false });
  const [respondents, setRespondents] = React.useState<IRespondent[]>([]);

  const [wordGameLeaderBoard, setWordGameLeaderBoard] = React.useState<
    IWordGameLeaderBoard[]
  >([]);

  const socket = useSocket();
  const queryClient = useQueryClient();
  const handleSocketError = useHandleSocketError();

  const handleStartCountdown = React.useCallback(() => {
    const nextQuestion = getNextQuestion(
      livePoll!,
      livePollSession!,
      questionResponseTimer,
    );
    setShowQuestionCountdown({ show: true, questionTitle: nextQuestion.title });
    emitStartQuestionCountDownEvent(
      socket,
      nextQuestion.id,
      livePollSession.id,
      handleSocketError((data: any) => {}),
    );
  }, [
    handleSocketError,
    livePoll,
    livePollSession,
    questionResponseTimer,
    socket,
  ]);

  React.useEffect(() => {
    socket.emit(
      EVENT_GET_RESPONDENT_INFO,
      {
        livePollSessionId: livePollSession.id,
      },
      (respondents: IRespondent[]) => {
        setRespondents(respondents);
      },
    );
    socket.emit(
      EVENT_GET_WORD_GAME_LEADERBOARD,
      {
        livePollSessionId: livePollSession.id,
      },

      (wordGameLeaderBoard: IWordGameLeaderBoard[]) => {
        setWordGameLeaderBoard([...wordGameLeaderBoard]);
      },
    );
  }, [socket, livePollSession.id, handleSocketError]);

  React.useEffect(() => {
    socket.on(
      EVENT_RESPONDENT_JOINED,
      handleSocketError((respondent: IRespondent) => {
        // already exists in the list
        if (
          respondents.some(r => r.id === respondent.id) ||
          respondent.livePollSessionId !== livePollSession.id
        ) {
          return;
        }
        setRespondents(oldRespondents => [...oldRespondents, respondent]);
      }),
    );

    socket.on(
      EVENT_RESPONDENT_LEFT,
      handleSocketError((respondent: IRespondent) => {
        if (!respondents.some(r => r.id === respondent.id)) {
          console.log('does not exist');
          return;
        }
        setRespondents(oldRespondents =>
          oldRespondents.filter(r => r.id !== respondent.id),
        );
      }),
    );

    return () => {
      socket.off(EVENT_RESPONDENT_JOINED);
      socket.off(EVENT_RESPONDENT_LEFT);
    };
  }, [handleSocketError, livePollSession.id, respondents, socket]);

  React.useEffect(() => {
    socket.on(
      EVENT_QUESTION_ANSWERED,
      handleSocketError((questionResponses: QuestionResponse[]) => {
        // We receive responses only question started state

        if (livePollSession.state !== LivePollSessionState.QUESTION_STARTED) {
          return;
        }

        const questionId = questionResponseTimer?.questionId!;

        queryClient.setQueryData<{
          questionId: string;
          responses: IChoiceQuestionAnswerCount[];
          leaderboard: { leaderboardRows: LeaderboardRow[] };
          responseCount: number;
          responseIds: number[];
        }>(`question-result-${questionId}`, oldData => {
          return getMultiChoiceAndMultiSelectQuestionResult(
            questionId,
            questionResponses,
            oldData,
          );
        });
      }),
    );

    return () => {
      socket.off(EVENT_QUESTION_ANSWERED);
    };
  }, [
    handleSocketError,
    livePollSession.state,
    queryClient,
    questionResponseTimer,
    socket,
  ]);

  React.useEffect(() => {
    socket.on(
      EVENT_LIVEFEED_QUESTION_ANSWERED,
      handleSocketError((data: LiveFeedQuestionResponse) => {
        // This response is for some other question??
        const isResponseForCurrentQuestion =
          !questionResponseTimer ||
          questionResponseTimer.questionId === data.questionId;
        if (!isResponseForCurrentQuestion) {
          return;
        }
        // We receive responses only question started state
        if (livePollSession.state !== LivePollSessionState.QUESTION_STARTED) {
          return;
        }
        const questionId = questionResponseTimer?.questionId;
        queryClient.setQueryData<{
          liveFeedResponses: LiveFeedQuestionResponse[];
        }>(`livefeed-question-result-${questionId}`, oldData => {
          const currentLiveFeedResponses = oldData?.liveFeedResponses || [];

          // This response is already recieved
          if (
            currentLiveFeedResponses.some(response => response.id === data.id)
          ) {
            if (!oldData) {
              return {
                questionId,
                liveFeedResponses: currentLiveFeedResponses,
                responseCount: currentLiveFeedResponses.length,
                responseCountForLiveFeedView: currentLiveFeedResponses.length,
              };
            }
            return oldData;
          }
          return {
            questionId,
            liveFeedResponses: [...currentLiveFeedResponses, data],
            responseCount: currentLiveFeedResponses.length + 1,
            responseCountForLiveFeedView: currentLiveFeedResponses.length + 1,
          };
        });
      }),
    );
    return () => {
      socket.off(EVENT_LIVEFEED_QUESTION_ANSWERED);
    };
  }, [
    handleSocketError,
    livePollSession.state,
    queryClient,
    questionResponseTimer,
    socket,
  ]);

  React.useEffect(() => {
    socket.on(
      EVENT_SWIPE_QUESTION_ANSWERED,
      handleSocketError(
        (data: {
          areAllCardsSwiped: boolean;
          selectedCards: { id: string; side: SwipeQuestionCardSide }[];
        }) => {
          // We receive responses only question started state
          if (livePollSession.state !== LivePollSessionState.QUESTION_STARTED) {
            return;
          }
          const questionId = questionResponseTimer?.questionId;
          queryClient.setQueryData<{
            responseCount: number;
            swipeQuestionResponses?: SwipeQuestionResponse[];
          }>(`swipe-question-result-${questionId}`, oldData => {
            const currentResponseCount = oldData?.responseCount || 0;

            const swipeQuestionResponses =
              oldData?.swipeQuestionResponses || [];

            data.selectedCards.forEach(({ id, side }) => {
              const existingCard = swipeQuestionResponses?.find(
                card => card.cardId === id,
              );

              if (existingCard) {
                if (side === SwipeQuestionCardSide.LEFT) {
                  existingCard.leftCount += 1;
                } else if (side === SwipeQuestionCardSide.RIGHT) {
                  existingCard.rightCount += 1;
                }
              } else {
                swipeQuestionResponses.push({
                  cardId: id,
                  leftCount: side === SwipeQuestionCardSide.LEFT ? 1 : 0,
                  rightCount: side === SwipeQuestionCardSide.RIGHT ? 1 : 0,
                });
              }
            });

            if (data.areAllCardsSwiped) {
              return {
                questionId,
                responseCount: currentResponseCount + 1,
                swipeQuestionResponses,
              };
            }
            return {
              questionId,
              responseCount: currentResponseCount,
              swipeQuestionResponses,
            };
          });
        },
      ),
    );
    return () => {
      socket.off(EVENT_SWIPE_QUESTION_ANSWERED);
    };
  }, [
    handleSocketError,
    livePollSession.state,
    queryClient,
    questionResponseTimer,
    socket,
  ]);

  React.useEffect(() => {
    socket.on(
      EVENT_WORD_CLOUD_QUESTION_ANSWERED,
      handleSocketError(
        (wordCloudQuestionResponse: WordCloudQuestionResponse) => {
          // We receive responses only question started state
          if (livePollSession.state !== LivePollSessionState.QUESTION_STARTED) {
            return;
          }
          const questionId = questionResponseTimer?.questionId;
          queryClient.setQueryData<{
            wordCloudResponses: WordCloudQuestionResponse[];
          }>(`word-cloud-question-result-${questionId}`, oldData => {
            const currentWordCloudResponses = oldData?.wordCloudResponses || [];
            // check if present
            const responseToUpdateIndex = currentWordCloudResponses.findIndex(
              wordCloudResponse =>
                wordCloudResponse.name === wordCloudQuestionResponse.name,
            );

            if (responseToUpdateIndex !== -1) {
              const updatedWordResponses = [...currentWordCloudResponses];

              updatedWordResponses[responseToUpdateIndex] = {
                ...updatedWordResponses[responseToUpdateIndex],
                weight: updatedWordResponses[responseToUpdateIndex].weight + 1,
              };
              return {
                questionId,
                wordCloudResponses: updatedWordResponses,
              };
            }
            return {
              questionId,
              wordCloudResponses: [
                ...currentWordCloudResponses,
                wordCloudQuestionResponse,
              ],
            };
          });
        },
      ),
    );
    return () => {
      socket.off(EVENT_WORD_CLOUD_QUESTION_ANSWERED);
    };
  }, [
    handleSocketError,
    livePollSession.state,
    queryClient,
    questionResponseTimer,
    socket,
  ]);

  React.useEffect(() => {
    socket.on(
      EVENT_LIVEFEED_COMMENT_VOTE,
      handleSocketError((data: LiveFeedQuestionResponse) => {
        // This response is for some other question??
        const isResponseForCurrentQuestion =
          !questionResponseTimer ||
          questionResponseTimer.questionId === data.questionId;

        if (!isResponseForCurrentQuestion) {
          return;
        }
        // We receive responses only question started state
        if (livePollSession.state !== LivePollSessionState.QUESTION_STARTED) {
          return;
        }

        const questionId = questionResponseTimer?.questionId;
        queryClient.setQueryData<{
          liveFeedResponses: LiveFeedQuestionResponse[];
        }>(`livefeed-question-result-${questionId}`, oldData => {
          const currentLiveFeedResponses = oldData?.liveFeedResponses || [];

          // find livePollResponse index by id
          const commentToUpdateIndex = currentLiveFeedResponses.findIndex(
            liveFeedResponse => liveFeedResponse.id === data.id,
          );
          if (commentToUpdateIndex !== -1) {
            const updatedLiveFeedResponse = [...currentLiveFeedResponses];
            updatedLiveFeedResponse[commentToUpdateIndex] = {
              ...updatedLiveFeedResponse[commentToUpdateIndex],
              upvoteCount: data.upvoteCount,
              downvoteCount: data.downvoteCount,
            };
            return {
              questionId,
              liveFeedResponses: updatedLiveFeedResponse,
              responseCount: updatedLiveFeedResponse.length,
              responseCountForLiveFeedView: updatedLiveFeedResponse.length,
            };
          }
          return {
            questionId,
            liveFeedResponses: [...currentLiveFeedResponses],
            responseCount: currentLiveFeedResponses.length,
            responseCountForLiveFeedView: currentLiveFeedResponses.length,
          };
        });
      }),
    );
    return () => {
      socket.off(EVENT_LIVEFEED_COMMENT_VOTE);
    };
  }, [
    handleSocketError,
    livePollSession.state,
    queryClient,
    questionResponseTimer,
    socket,
  ]);

  React.useEffect(() => {
    socket.on(
      EVENT_WORD_GAME_UPDATE_SCORE,
      handleSocketError((data: IWordGameLeaderBoard[]) => {
        if (livePollSession.state !== LivePollSessionState.WAITING_ROOM) {
          return;
        }
        setWordGameLeaderBoard([...data]);
      }),
    );
    return () => {
      socket.off(EVENT_WORD_GAME_UPDATE_SCORE);
    };
  }, [socket, handleSocketError, livePollSession.state]);

  React.useEffect(() => {
    socket.on(
      EVENT_LAST_PROFILE_QUESTION_ANSWERED,
      handleSocketError((respondentId: number) => {
        const oldRespondents = [...respondents];
        const updatedRespondent = oldRespondents.find(
          r => r.id === respondentId,
        )!;
        updatedRespondent.isProfileQuestionsCompleted = true;
        setRespondents(oldRespondents);
      }),
    );
    return () => {
      socket.off(EVENT_LAST_PROFILE_QUESTION_ANSWERED);
    };
  }, [handleSocketError, respondents, socket]);

  React.useEffect(() => {
    socket.on(
      EVENT_RECEIVED_RESPONDENT_TEAM_UID,
      handleSocketError((data: { respondentId: number; teamUid: string }) => {
        const respondentId = data.respondentId;
        const teamUid = data.teamUid;
        const oldRespondents = [...respondents];
        const updatedRespondent = oldRespondents.find(
          r => r.id === respondentId,
        )!;
        updatedRespondent.teamUid = teamUid;
        setRespondents(oldRespondents);
      }),
    );
    return () => {
      socket.off(EVENT_RECEIVED_RESPONDENT_TEAM_UID);
    };
  }, [socket, handleSocketError, livePollSession.state, respondents]);

  const value = React.useMemo(
    () => ({
      showQuestionCountdown,
      livePoll: livePoll!,
      livePollSession: livePollSession!,
      questionResponseTimer,
      respondents,
      handleStartCountdown,
      setShowQuestionCountdown,
      wordGameLeaderBoard,
      presenterNotesWindow,
    }),
    [
      showQuestionCountdown,
      livePoll,
      livePollSession,
      questionResponseTimer,
      respondents,
      handleStartCountdown,
      wordGameLeaderBoard,
      presenterNotesWindow,
    ],
  );

  return (
    <RunLivePollSessionContext.Provider value={value}>
      {children}
    </RunLivePollSessionContext.Provider>
  );
};

export const useRunLivePollSessionContext = () => {
  const context = useContext(RunLivePollSessionContext);
  if (!context) {
    throw new Error(
      `useRunLivePollSessionContext must be used within a RunLivePollSessionContextProvider`,
    );
  }
  return context;
};

export const useCurrentQuestion = () => {
  const context = useContext(RunLivePollSessionContext);
  if (!context) {
    throw new Error(
      `useCurrentQuestion must be used within a RunLivePollSessionContextProvider`,
    );
  }

  const { livePoll, livePollSession, questionResponseTimer } = context;

  const questions = livePoll.questions;
  if (!questions) {
    throw new Error(`livePoll.questions is undefined`);
  }

  if (livePollSession.state === LivePollSessionState.WAITING_ROOM) {
    throw new Error(`livePollSession is in waiting room`);
  }

  if (!questionResponseTimer) {
    throw new Error('questionResponseTimer is undefined');
  }

  const currentQuestionId = questionResponseTimer.questionId;

  const question = questions.find(q => q.id === currentQuestionId);
  if (!question) {
    throw new Error(`question not found for id: ${currentQuestionId}`);
  }

  return question;
};

const calculateStartDelayInMs = (
  questionResponseTimer?: IQuestionResponseTimer,
) => {
  const startAt = questionResponseTimer?.startAt;
  if (!startAt) {
    return 0;
  }

  const startDelayInMs = new Date(startAt).getTime() - Date.now();
  return startDelayInMs;
};

export const useStartQuestionMutation = () => {
  const context = useContext(RunLivePollSessionContext);
  if (!context) {
    throw new Error(
      `useStartQuestionMutation must be used within a RunLivePollSessionContextProvider`,
    );
  }
  const {
    livePoll,
    livePollSession,
    questionResponseTimer,
    setShowQuestionCountdown,
  } = context;
  const queryClient = useQueryClient();
  const socket = useSocket();
  const handleSocketError = useHandleSocketError();

  return useMutation('useStartQuestionMutation', () => {
    return new Promise<void>((resolve, reject) => {
      const nextQuestion = getNextQuestion(
        livePoll!,
        livePollSession!,
        questionResponseTimer,
      );

      const questionId = nextQuestion.id;
      const livePollSessionId = livePollSession.id;

      emitStartQuestionEvent(
        socket,
        questionId,
        livePollSessionId,
        handleSocketError(async data => {
          const startDelay = calculateStartDelayInMs(
            data.questionResponseTimer,
          );
          // Wait for start delay before displaying question
          if (startDelay > 0) {
            await new Promise(resolve => setTimeout(resolve, startDelay));
          }

          const { questionResponseTimerId } = data.livePollSession;
          queryClient.setQueryData(
            `question-response-timer-${questionResponseTimerId}`,
            data.questionResponseTimer,
          );

          // Reset current question result for choice question
          queryClient.setQueryData(`question-result-${questionId}`, {
            questionId,
            responses: [],
            leaderboard: { leaderboardRows: [] },
            responseCount: 0,
          });

          // Reset current question result for liveFeed question
          queryClient.setQueryData(`livefeed-question-result-${questionId}`, {
            questionId,
            liveFeedResponses: [],
            responseCount: 0,
            responseCountForLiveFeedView: 0,
          });

          queryClient.setQueryData(
            [
              `livefeed-question-result-${questionId}`,
              getDefaultValuesForLiveFeedResultPagination(),
            ],
            {
              questionId,
              liveFeedResponses: [],
              responseCount: 0,
              responseCountForLiveFeedView: 0,
            },
          );

          queryClient.setQueryData(`word-cloud-question-result-${questionId}`, {
            questionId,
            wordCloudResponses: [],
          });

          queryClient.setQueryData(`swipe-question-result-${questionId}`, {
            questionId,
            responseCount: 0,
            swipeQuestionResponses: [],
          });

          queryClient.setQueryData(
            `livepoll-session-${livePollSessionId}`,
            data.livePollSession,
          );

          setShowQuestionCountdown({ show: false });
          resolve();
        }),
      );
    });
  });
};

export const useFinishQuestionMutation = () => {
  const context = useContext(RunLivePollSessionContext);
  if (!context) {
    throw new Error(
      `useFinishQuestionMutation must be used within a RunLivePollSessionContextProvider`,
    );
  }
  const { livePollSession } = context;
  const queryClient = useQueryClient();
  const socket = useSocket();
  const currentQuestion = useCurrentQuestion();
  const handleSocketError = useHandleSocketError();

  return useMutation('useFinishQuestionMutation', () => {
    return new Promise<void>((resolve, reject) => {
      const questionId = currentQuestion.id;
      const livePollSessionId = livePollSession.id;
      const questionType = currentQuestion.type;
      const defaultLiveFeedPaginationValues =
        getDefaultValuesForLiveFeedResultPagination();

      emitFinishQuestionEvent(
        socket,
        livePollSessionId,
        questionId,
        handleSocketError(data => {
          const { questionResponseTimerId } = data.livePollSession;
          queryClient.setQueryData(
            `question-response-timer-${questionResponseTimerId}`,
            data.questionResponseTimer,
          );

          if (questionType === QuestionType.CHOICE) {
            queryClient.setQueryData(`question-result-${questionId}`, {
              questionId,
              responses: data.responses,
              leaderboard: {
                leaderboardRows: data.leaderboardRows,
              },
              responseCount: data.responseCount,
              responsesByTeam: data.responsesByTeam,
              topThreeFastestResponderInfo: data.topThreeFastestResponderInfo,
              respondentAnswerStreak: data.respondentAnswerStreak,
              respondentRankJump: data.respondentRankJump,
            });
          } else if (questionType === QuestionType.LIVE_FEED) {
            const liveFeedResponses = data.liveFeedResponses;
            queryClient.setQueryData(
              [
                `livefeed-question-result-${questionId}`,
                defaultLiveFeedPaginationValues,
              ],
              {
                questionId,
                liveFeedResponses: liveFeedResponses,
                responseCount: liveFeedResponses.length,
                responseCountForLiveFeedView: liveFeedResponses.length,
              },
            );
          } else if (questionType === QuestionType.WORD_CLOUD) {
            queryClient.setQueryData(
              `word-cloud-question-result-${questionId}`,
              {
                questionId,
                wordCloudResponses: data.wordCloudResponses,
              },
            );
          } else if (questionType === QuestionType.SWIPE) {
            queryClient.setQueryData(`swipe-question-result-${questionId}`, {
              questionId,
              swipeQuestionResponses: data.swipeQuestionResponses,
            });
          } else {
            throw new Error('Incorrect question type');
          }

          queryClient.setQueryData(
            `livepoll-session-${livePollSessionId}`,
            data.livePollSession,
          );

          resolve();
        }),
        defaultLiveFeedPaginationValues,
      );
    });
  });
};

export const useFinishLivePollSessionMutation = () => {
  const context = useContext(RunLivePollSessionContext);
  if (!context) {
    throw new Error(
      `useFinishLivePollSessionMutation must be used within a RunLivePollSessionContextProvider`,
    );
  }
  const { livePollSession } = context;
  const queryClient = useQueryClient();
  const socket = useSocket();
  const handleSocketError = useHandleSocketError();

  return useMutation('useFinishLivePollSessionMutation', () => {
    return new Promise<void>((resolve, reject) => {
      const livePollSessionId = livePollSession.id;

      emitFinishLivePollSessionEvent(
        socket,
        livePollSessionId,
        handleSocketError(async data => {
          queryClient.setQueryData(
            `livepoll-session-${livePollSessionId}`,
            data.livePollSession,
          );
          /**
           * This is causing the app to stuck on Loading screen,
           * once LivePoll session is finished.
           */
          // socket.disconnect();
          resolve();
        }),
      );
    });
  });
};

export const useTerminateLivePollSessionMutation = () => {
  const context = useContext(RunLivePollSessionContext);
  if (!context) {
    throw new Error(
      `useTerminateLivePollSessionMutation must be used within a RunLivePollSessionContextProvider`,
    );
  }
  const { livePollSession } = context;
  const queryClient = useQueryClient();
  const socket = useSocket();
  const handleSocketError = useHandleSocketError();

  return useMutation('useTerminateLivePollSessionMutation', () => {
    return new Promise<void>((resolve, reject) => {
      const livePollSessionId = livePollSession.id;

      emitTerminateLivePollSessionEvent(
        socket,
        livePollSessionId,
        handleSocketError(async data => {
          queryClient.setQueryData(
            `livepoll-session-${livePollSessionId}`,
            data.livePollSession,
          );
          resolve();
          context.presenterNotesWindow?.close();
          socket.disconnect();
        }),
      );
    });
  });
};

export const useChangeRespondentTeamUidMutation = () => {
  const context = useContext(RunLivePollSessionContext);
  if (!context) {
    throw new Error(
      `useChangeRespondentTeamUidMutation must be used within a RunLivePollSessionContextProvider`,
    );
  }

  const socket = useSocket();
  const handleSocketError = useHandleSocketError();

  return useMutation(
    'useChangeRespondentTeamUidMutation',
    ({ respondentId, teamUid }: { respondentId: number; teamUid: string }) => {
      return new Promise<void>((resolve, reject) => {
        const { respondents, livePollSession } = context;

        emitChangeRespondentTeamUid(
          socket,
          livePollSession.id,
          respondentId,
          teamUid,
          handleSocketError(async data => {
            const oldRespondents = [...respondents];
            const updatedRespondent = oldRespondents.find(
              r => r.id === respondentId,
            )!;
            updatedRespondent.teamUid = teamUid;

            resolve();
            return {
              respondents: [...oldRespondents],
            };
          }),
        );
      });
    },
  );
};

export const useSetTeamUidWhenDragAndDropUpdateMutation = () => {
  const context = useContext(RunLivePollSessionContext);
  if (!context) {
    throw new Error(
      `useSetTeamUidWhenDragAndDropUpdateMutation must be used within a RunLivePollSessionContextProvider`,
    );
  }

  return useMutation(
    'useSetTeamUidWhenDragAndDropUpdateMutation',
    ({ respondentId, teamUid }: { respondentId: number; teamUid: string }) => {
      return new Promise<void>((resolve, reject) => {
        const { respondents } = context;

        const oldRespondents = [...respondents];
        const updatedRespondent = oldRespondents.find(
          r => r.id === respondentId,
        )!;
        updatedRespondent.teamUid = teamUid;

        resolve();
        return {
          respondents: [...oldRespondents],
        };
      });
    },
  );
};

export const useLiveFeedCommentPinUnpinMutation = () => {
  const context = useContext(RunLivePollSessionContext);
  if (!context) {
    throw new Error(
      `useLiveFeedCommentPinUnpinMutation must be used within a RunLivePollSessionContextProvider`,
    );
  }

  const currentQuestion = useCurrentQuestion();
  const socket = useSocket();
  const queryClient = useQueryClient();
  const handleSocketError = useHandleSocketError();

  return useMutation(
    'usePinLiveFeedCommentMutation',
    ({
      commentId,
      pinUnpin,
    }: {
      commentId: number;
      pinUnpin: LiveFeedPinUnpin;
    }) => {
      return new Promise<void>((resolve, reject) => {
        const questionId = currentQuestion.id;

        emitPinLiveFeedComment(
          socket,
          commentId,
          pinUnpin,
          handleSocketError(async data => {
            queryClient.setQueryData<{
              liveFeedResponses: LiveFeedQuestionResponse[];
            }>(`livefeed-question-result-${questionId}`, oldData => {
              const currentLiveFeedResponses = oldData?.liveFeedResponses || [];

              // data contains count of rows affected
              if (data === 1) {
                // find livePollResponse index by id
                const commentToUpdateIndex = currentLiveFeedResponses.findIndex(
                  liveFeedResponse => liveFeedResponse.id === commentId,
                );
                if (commentToUpdateIndex !== -1) {
                  const newLiveFeedResponses = [...currentLiveFeedResponses];
                  newLiveFeedResponses[commentToUpdateIndex] = {
                    ...newLiveFeedResponses[commentToUpdateIndex],
                    isPinned: pinUnpin,
                  };
                  return {
                    questionId,
                    liveFeedResponses: newLiveFeedResponses,
                    responseCount: newLiveFeedResponses.length,
                    responseCountForLiveFeedView: newLiveFeedResponses.length,
                  };
                }
              }
              return {
                questionId,
                liveFeedResponses: [...currentLiveFeedResponses],
                responseCount: currentLiveFeedResponses.length,
                responseCountForLiveFeedView: currentLiveFeedResponses.length,
              };
            });
            resolve();
          }),
        );
      });
    },
  );
};

export const usePendingQuestionCount = () => {
  const context = useContext(RunLivePollSessionContext);
  if (!context) {
    throw new Error(
      `usePendingQuestion must be used within a RunLivePollSessionContextProvider`,
    );
  }

  const { livePoll, questionResponseTimer } = context;

  const questions = livePoll.questions;
  if (!questions) {
    throw new Error(`livePoll.questions is undefined`);
  }

  if (!questionResponseTimer) {
    return questions.length;
  }

  const currentQuestionId = questionResponseTimer.questionId;
  if (!currentQuestionId) {
    return questions.length;
  }
  const index = questions.findIndex(q => q.id === currentQuestionId);

  return questions.length - (index + 1);
};

const getThreeRespondentsNames = (
  respondentNames: string[] | undefined,
  respondentName: string,
) => {
  if (!respondentNames || respondentNames.length === 0) {
    return [respondentName];
  } else if (respondentNames.length >= 3) {
    return respondentNames;
  } else {
    const newRespondents = [...respondentNames, respondentName];
    return newRespondents;
  }
};

const getMultiChoiceAndMultiSelectQuestionResult = (
  questionId: string,
  questionResponses: QuestionResponse[],
  oldData?: {
    questionId: string;
    responses: IChoiceQuestionAnswerCount[];
    leaderboard: { leaderboardRows: LeaderboardRow[] };
    responseCount: number;
    responseIds: number[];
  },
) => {
  let currentResponseIds = oldData?.responseIds || [];
  let currentResponseCount = oldData?.responseCount || 0;
  let currentResponses = oldData?.responses || [];

  const uniqueRespondentIds = new Set();
  const isMultiSelectQuestion = questionResponses.some(
    qResponse => !!qResponse.isMultiSelect,
  );

  for (const questionResponse of questionResponses) {
    const isMultiSelect = !!questionResponse.isMultiSelect;

    // skip if the response is already received and question is not a multi-select question
    if (currentResponseIds.includes(questionResponse.id) && !isMultiSelect) {
      console.log(`No change question-result-${questionId}`);
      continue;
    }

    const answerIdWithCountIdx = currentResponses.findIndex(
      response => response.answerId === questionResponse.answerId,
    );

    if (answerIdWithCountIdx === -1) {
      currentResponses = [
        ...currentResponses,
        {
          answerId: questionResponse.answerId,
          count: 1,
          threeRespondentNames: [questionResponse.respondentName],
        },
      ];
    } else {
      const threeRespondentNames = getThreeRespondentsNames(
        currentResponses[answerIdWithCountIdx].threeRespondentNames,
        questionResponse.respondentName,
      );

      currentResponses = [
        ...currentResponses.slice(0, answerIdWithCountIdx),
        {
          answerId: currentResponses[answerIdWithCountIdx].answerId,
          count: currentResponses[answerIdWithCountIdx].count + 1,
          threeRespondentNames,
        },
        ...currentResponses.slice(answerIdWithCountIdx + 1),
      ];
    }

    if (isMultiSelect) {
      uniqueRespondentIds.add(questionResponse.respondentId);
    } else {
      currentResponseCount += 1;
    }
    currentResponseIds = [...currentResponseIds, questionResponse.id];
  }

  if (isMultiSelectQuestion && uniqueRespondentIds.size > 0) {
    currentResponseCount += uniqueRespondentIds.size;
  }

  return {
    questionId,
    responses: currentResponses,
    responseCount: currentResponseCount,
    leaderboard: { leaderboardRows: [] },
    responseIds: currentResponseIds,
  };
};
