import { useEffect, useState } from 'react';
import styles from './LpSwipeQuestionChartForQuiz.module.css';
import { LpSwipeQuestionHeader } from './components/LpSwipeQuestionHeader';
import { ChartView } from '../../../enums/chart-view.enum';
import { SortOrder } from '../../../enums/sort-order.enum';
import { SwipeQuestionChartView } from '../../../enums/teams-chart-view.enum';
import { SwipeQuestionCard } from '../../../interfaces/swipe-question-card.interface';
import { SwipeQuestionOption } from '../../../interfaces/swipe-question-option.interface';
import { LpSwipeQuestionResultForTeams } from './teams/LpQuestionResultForTeams';
import { LpSwipeQuestionResultForQuiz } from './components/LpSwipeQuestionResultForQuiz';
import { SwipeQuestionResponse } from '../../../interfaces/swipe-question-response.interface';
import { SwipeQuestionTeamResponse } from '../../../interfaces/swipe-question-team-response.interface';
import { SwipeQuestionFinishDataWithRank } from '../../../interfaces/swipe-question-finish-data-with-rank.interface';
import { SwipeQuestionCardSide } from '../../../interfaces/swipe-question-card-side.interface';
import { ITeam } from '../../../interfaces/team.interface';

interface Props {
  responses: SwipeQuestionResponse[];
  question: { swipeOptions: SwipeQuestionOption; cards: SwipeQuestionCard[] };
  isTeamsEnabled: boolean;
  teamResponses: SwipeQuestionTeamResponse[];
  isReportScreen?: boolean;
  teams?: ITeam[];
  text: {
    sortBy: string;
    default: string;
    accuracy: string;
    swipeQuestionCorrectText: string;
    swipeQuestionIncorrectText: string;
    teamRankings: string;
    individualView: string;
    teamsView: string;
  };
}

export const LpSwipeQuestionChartForQuiz = ({
  responses,
  teamResponses,
  question,
  isTeamsEnabled,
  teams,
  isReportScreen,
  text,
}: Props) => {
  const [currentView, setCurrentView] = useState<ChartView>(
    ChartView.CHRONOLOGICAL,
  );
  const [order, setOrder] = useState<SortOrder>(SortOrder.DESC);
  const [sortedResponses, setSortedResponses] = useState<
    SwipeQuestionFinishDataWithRank[]
  >([]);
  const [teamsOrIndividualView, setTeamsOrIndividualView] =
    useState<SwipeQuestionChartView>(SwipeQuestionChartView.INDIVIDUAL);

  const onChangeView = (view: ChartView) => {
    setCurrentView(view);
  };

  const onChangeOrder = (order: SortOrder) => {
    setOrder(order);
  };

  const onChangeTeamsOrIndividualView = (view: SwipeQuestionChartView) => {
    setTeamsOrIndividualView(view);
  };

  useEffect(() => {
    const cards = question.cards;

    const prepareSwipeQuestionResult = (
      swipeQuestionResponses: SwipeQuestionResponse[] | undefined,
    ) => {
      if (!swipeQuestionResponses) {
        return [];
      }
      const rankedResponses = swipeQuestionResponses
        .map(item => ({
          ...item,
          percentage: getPercentageForCorrectOption(item, item.cardId, cards),
        }))
        .sort((a, b) => Number(b.percentage) - Number(a.percentage))
        .map((item, index) => ({
          ...item,
          rank: index + 1,
        }));

      const cardIdsWithResponses = new Set(
        swipeQuestionResponses.map(r => r.cardId),
      );
      const cardsWithoutResponses = cards.filter(
        card => !cardIdsWithResponses.has(card.id),
      );
      const lastRank = rankedResponses.length;
      cardsWithoutResponses.forEach((card, index) => {
        const currentIndex = index + 1;
        rankedResponses.push({
          cardId: card.id,
          rightCount: 0,
          leftCount: 0,
          percentage: 0,
          rank: lastRank + currentIndex,
        });
      });

      return rankedResponses;
    };

    const result = prepareSwipeQuestionResult(responses);

    setSortedResponses(result);
  }, [question.cards, responses]);

  return (
    <div
      className={styles.container}
      data-testid="swipe question finished for quiz"
    >
      <LpSwipeQuestionHeader
        currentView={currentView}
        order={order}
        teamsOrIndividualView={teamsOrIndividualView}
        onChangeView={onChangeView}
        onChangeOrder={onChangeOrder}
        onChangeTeamsOrIndividualView={onChangeTeamsOrIndividualView}
        isTeamsEnabled={isTeamsEnabled && teams != null && teams.length > 0}
        text={text}
      />
      {teamsOrIndividualView === SwipeQuestionChartView.INDIVIDUAL && (
        <LpSwipeQuestionResultForQuiz
          responsesWithRank={sortedResponses}
          question={question}
          view={currentView}
          order={order}
          isReportScreen={isReportScreen}
        />
      )}

      {teamsOrIndividualView === SwipeQuestionChartView.TEAMS && (
        <LpSwipeQuestionResultForTeams
          responsesWithRank={sortedResponses}
          teamResponses={teamResponses}
          teams={teams || []}
          question={question}
          view={currentView}
          order={order}
          isReportScreen={isReportScreen}
        />
      )}
    </div>
  );
};

const getPercentageForCorrectOption = (
  response: SwipeQuestionResponse,
  cardId: string,
  cards: SwipeQuestionCard[],
) => {
  const card = cards.find(card => card.id === cardId);
  const total = Number(response.leftCount) + Number(response.rightCount);

  if (
    card?.correctSide === SwipeQuestionCardSide.LEFT &&
    response.leftCount > 0
  ) {
    return (response.leftCount * 100) / total;
  }

  if (
    card?.correctSide === SwipeQuestionCardSide.RIGHT &&
    response.rightCount > 0
  ) {
    return (response.rightCount * 100) / total;
  }

  return 0;
};
