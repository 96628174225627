import { LivePollSessionState } from '@livepolls/ui-components/src/enums/livepoll-session-state.enum';

export function isLivePollSessionClosed(
  livePollSessionState: LivePollSessionState,
) {
  return (
    livePollSessionState === LivePollSessionState.COMPLETED ||
    livePollSessionState === LivePollSessionState.TERMINATED_USER ||
    livePollSessionState === LivePollSessionState.TERMINATED_SYSTEM
  );
}
