import {
  useRunLivePollSessionContext,
  useStartQuestionMutation,
} from 'src/contexts/runLivePollSessionContext';
import { formatPluralizedText } from 'src/utils/getPluralizedText.util';
import styles from './WaitingRoomScreen.module.css';
import { WuButton } from '@npm-questionpro/wick-ui-lib';
import { LivePollType } from '@livepolls/ui-components/src/enums/livepoll-type.enum';
import { LivePollSessionHeaderForZoom } from '../../session-header/LivePollSessionHeaderForZoom';
import { CountDownForZoom } from '../../countdown/CountDownForZoom';
import { useEffect } from 'react';
import { sendAppInvitationToAllParticipants } from 'src/utils/zoom-sdk.utils';
import { addZoomAppInvitation } from 'src/hooks/store-hooks';

export const WaitingRoomScreen = () => {
  const {
    handleStartCountdown,
    respondents,
    livePoll,
    showQuestionCountdown,
    livePollSession,
  } = useRunLivePollSessionContext();
  const startQuestionMutation = useStartQuestionMutation();
  const isQuiz = livePoll.type === LivePollType.QUIZ;

  useEffect(() => {
    const sendInvitation = async () => {
      const response = await sendAppInvitationToAllParticipants();
      const invitationId = response?.invitationId || '';
      const meetingId = response?.meetingId || '';

      if (invitationId || meetingId) {
        await addZoomAppInvitation(livePollSession.id, invitationId, meetingId);
      }
    };
    sendInvitation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showQuestionCountdown.show || startQuestionMutation.isLoading) {
    return <CountDownForZoom />;
  }

  const noRespondents = respondents.length === 0;

  const zoomSessionFooter = () => {
    const buttonText = isQuiz ? 'Start quiz' : 'Start poll';
    return (
      <div className={styles.footer}>
        <WuButton
          variant="primary"
          onClick={handleStartCountdown}
          disabled={noRespondents}
          icon={<span className={`wm-arrow-right ${styles.rightArrow}`} />}
          iconPosition="right"
        >
          {buttonText}
        </WuButton>
      </div>
    );
  };
  const reverseRespondents = respondents.reverse();
  return (
    <>
      <LivePollSessionHeaderForZoom />
      <div className={styles.container}>
        {noRespondents ? (
          <div className={styles.waitingRoomText}>
            Waiting for respondents to join.
          </div>
        ) : (
          <>
            <div className={styles.participantText}>
              {formatPluralizedText(respondents?.length || 0, 'participant')}
            </div>
            <div className={styles.participantContainer}>
              {reverseRespondents.map(resp => {
                return (
                  <div key={resp.id} className={styles.respondent}>
                    {resp.respondentName}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
      {zoomSessionFooter()}
    </>
  );
};
