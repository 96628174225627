import {
  useCurrentQuestion,
  useFinishQuestionMutation,
  useRunLivePollSessionContext,
} from 'src/contexts/runLivePollSessionContext';
import styles from './QuestionStartedScreen.module.css';
import { IChoiceQuestion } from '@livepolls/ui-components/src/interfaces/choice-question.interface';
import { WuButton } from '@npm-questionpro/wick-ui-lib';
import { QuestionType } from '@livepolls/ui-components/src/enums/question-type';
import { ChoiceQuestionStartedForZoom } from './choice/ChoiceQuestionStarted';
import { LiveQuestionStartedForZoom } from './livefeed/LiveFeedQuestionStartedForZoom';
import { WordCloudQuestionStartedForZoom } from './word-cloud/WordCloudQuestionStartedForZoom';
import { SwipeQuestionStartedForZoom } from './swipe/SwipeQuestionStartedForZoom';
import { LivePollType } from '@livepolls/ui-components/src/enums/livepoll-type.enum';

export const QuestionStartedScreen = () => {
  const { livePoll, livePollSession } = useRunLivePollSessionContext();
  const finishQuestionMutation = useFinishQuestionMutation();
  const currentQuestion = useCurrentQuestion() as IChoiceQuestion;
  const isQuiz = livePoll.type === LivePollType.QUIZ;

  const zoomSessionFooter = () => {
    return (
      <div className={styles.footer}>
        <WuButton
          variant="primary"
          onClick={handleFinishQuestion}
          icon={<span className={`wm-arrow-right ${styles.rightArrow}`} />}
          iconPosition="right"
        >
          Finish Question
        </WuButton>
      </div>
    );
  };

  const handleFinishQuestion = async () => {
    finishQuestionMutation.isIdle &&
      (await finishQuestionMutation.mutateAsync());
  };

  return (
    <>
      {currentQuestion.type === QuestionType.CHOICE && (
        <ChoiceQuestionStartedForZoom
          questionTitle={currentQuestion.title || ''}
          answers={currentQuestion.answers}
          questionId={currentQuestion.id}
          livePollId={livePollSession.livePollId}
          livePollSessionId={livePollSession.id}
        />
      )}

      {currentQuestion.type === QuestionType.LIVE_FEED && (
        <LiveQuestionStartedForZoom
          questionTitle={currentQuestion.title || ''}
          questionId={currentQuestion.id}
          livePollId={livePollSession.livePollId}
          livePollSessionId={livePollSession.id}
        />
      )}

      {currentQuestion.type === QuestionType.WORD_CLOUD && (
        <WordCloudQuestionStartedForZoom
          questionTitle={currentQuestion.title || ''}
          questionId={currentQuestion.id}
          livePollId={livePollSession.livePollId}
          livePollSessionId={livePollSession.id}
        />
      )}

      {currentQuestion.type === QuestionType.SWIPE && (
        <SwipeQuestionStartedForZoom
          questionTitle={currentQuestion.title || ''}
          questionId={currentQuestion.id}
          livePollId={livePollSession.livePollId}
          livePollSessionId={livePollSession.id}
          isQuiz={isQuiz}
        />
      )}

      {zoomSessionFooter()}
    </>
  );
};
