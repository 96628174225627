import { IDownloadFileResponse } from '@livepolls/ui-components/src/interfaces/download-file-response.interface';
import { IDownloadFile } from '@livepolls/ui-components/src/interfaces/download-file.interface';
import { startDownloadableReportCreation } from 'src/hooks/store-hooks';
import { v4 as uuidv4 } from 'uuid';
import create from 'zustand';

export interface IDownloadReportStore {
  loading: boolean;
  error: null;
  files: IDownloadFile[];
  setFiles: (files: IDownloadFile[]) => void;
  addFile: (file: IDownloadFile) => void;
  updateFile: (file: IDownloadFile) => void;
  startDownloadableReportCreation: (livePollSessionId: number) => void;
  removeFile: (id: string) => void;
  updateProgress: (data: IDownloadFileResponse[]) => void;
  updateError: (id: string, err: Error) => void;
  updateIsDownloaded: (id: string) => void;
  resetStore: () => void;
}

export const useDownloadReportStore = create<IDownloadReportStore>(
  (set, get) => ({
    files: [],
    error: null,
    loading: false,

    setFiles: (files: IDownloadFile[]) => {
      try {
        set(state => {
          state.files = files;
        });
      } catch (err: any) {
        set({ error: err });
      }
      set({ loading: false });
    },

    removeFile: (id: string) => {
      try {
        set(state => ({
          files: state.files.filter(file => file.id !== id),
        }));
      } catch (err: any) {
        set({ error: err });
      }
      set({ loading: false });
    },

    addFile: (file: IDownloadFile) => {
      try {
        const allFiles = get().files;
        set(state => {
          state.files = [file, ...allFiles];
        });
      } catch (err: any) {
        set({ error: err });
      }
      set({ loading: false });
    },

    updateFile: (updatedFile: IDownloadFile) => {
      set(state => ({
        files: state.files.map(file =>
          file.id === updatedFile.id ? { ...file, ...updatedFile } : file,
        ),
      }));
    },

    startDownloadableReportCreation: async (livePollSessionId: number) => {
      set({ loading: true });

      const id = uuidv4();
      const file: IDownloadFile = {
        id,
        name: '',
        filePath: '',
        creationId: -1,
        progress: 0,
        creationTime: new Date(),
      };
      get().addFile(file);
      try {
        const response = await startDownloadableReportCreation(
          livePollSessionId,
        );
        get().updateFile({
          ...file,
          creationId: response.creationId,
          creationTime: response.creationTime,
          name: response.fileName,
        });
      } catch (err: any) {
        get().updateFile({
          ...file,
          error: err,
        });
      }

      set({ loading: false });
    },
    updateProgress: (progressData: IDownloadFileResponse[]) => {
      progressData.forEach(res => {
        const files = get().files.filter(
          file => file.creationId === res.creationId,
        );
        if (files) {
          files.forEach(file => {
            const updatedFile = {
              ...file,
              progress: res.progress,
              filePath: res.filePath,
              error: res.error,
            };
            get().updateFile(updatedFile);
          });
        }
      });
    },
    updateError: (id: string, err: Error) => {
      const file = get().files.find(file => file.id === id);
      if (file) {
        get().updateFile({
          ...file,
          error: err,
        });
      }
    },
    updateIsDownloaded: (id: string) => {
      const file = get().files.find(file => file.id === id);
      if (file) {
        get().updateFile({
          ...file,
          isDownloaded: true,
        });
      }
    },

    resetStore: () =>
      set(state => {
        state.files = [];
        state.error = null;
        state.loading = false;
      }),
  }),
);
