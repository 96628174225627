import { UseMutationResult } from 'react-query';
import { useCallback, useEffect, useState } from 'react';
import { LpDropdown } from '../dropdown/LpDropdown';
import { LiveFeedComment } from './components/LiveFeedComment';
import { LiveFeedSvg } from '../svgImages/Livefeed.svg';
import { LiveFeedStatsDropdownEnum } from '../../enums/live-feed-stats-dropdown.enum';
import { LiveFeedQuestionResponse } from '../../interfaces/livefeed-question-response';
import { LiveFeedPinUnpin } from '../../enums/livefeed-pin-unpin.enum';
import { IPaginateDropdown } from '../../interfaces/paginate-dropdown.interface';
import styles from './LpLiveFeedStats.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { LikeSvg } from '../svgImages/Like.svg';
import { WuTooltip } from '@npm-questionpro/wick-ui-lib';
import ascendingSvg from '../../images/ascending.svg';
import descendingSvg from '../../images/descending.svg';
import { SortOrder } from '../../enums/sort-order.enum';
import { LpSpinner } from '../spinner/LpSpinner';

interface Props {
  isLoading: boolean;
  dropDownText: LiveFeedStatsDropdownEnum;
  liveFeedQuestionResponses: LiveFeedQuestionResponse[];
  onDropDownChange: (dropDownText: LiveFeedStatsDropdownEnum) => void;
  noResponsesText?: string;
  commentPinUnpin?: () => UseMutationResult<
    void,
    unknown,
    { commentId: number; pinUnpin: LiveFeedPinUnpin },
    unknown
  >;
  totalItems?: number;
  pager?: IPaginateDropdown;
  onPageChange?: (pager: IPaginateDropdown) => void;
  resultScreen?: boolean;
  text: {
    liveFeed: string;
    topVoted: string;
    bookmarked: string;
    comments: string;
    showing: string;
    showUpTo: string;
    of: string;
    to: string;
    invalidDropdownError: string;
    noComments: string;
    noVotesForComments: string;
    noBookmarkedComments: string;
    submissionTime: string;
    name: string;
    favorites: string;
    votes: string;
  };
}

export enum LiveFeedSortBy {
  SUBMISSION_TIME,
  NAME,
  FAVOURITES,
  VOTES,
}

export const LpLiveFeedStats = ({
  dropDownText,
  liveFeedQuestionResponses,
  onDropDownChange,
  noResponsesText,
  commentPinUnpin,
  isLoading,
  resultScreen,
  text,
}: Props) => {
  const [sortedLiveFeedResponses, setSortedLiveFeedResponses] = useState<
    LiveFeedQuestionResponse[]
  >(liveFeedQuestionResponses);
  const [order, setOrder] = useState<SortOrder>(SortOrder.DESC);
  const [sortBy, setSortBy] = useState<LiveFeedSortBy>(
    LiveFeedSortBy.SUBMISSION_TIME,
  );

  useEffect(() => {
    const sortResponses = (
      responses: LiveFeedQuestionResponse[],
      comparator: (
        a: LiveFeedQuestionResponse,
        b: LiveFeedQuestionResponse,
      ) => number,
    ) => {
      const sorted = [...responses].sort(comparator);
      return order === SortOrder.DESC ? sorted : sorted.reverse();
    };

    switch (sortBy) {
      case LiveFeedSortBy.SUBMISSION_TIME:
        setSortedLiveFeedResponses(
          order === SortOrder.DESC
            ? [...liveFeedQuestionResponses]
            : [...liveFeedQuestionResponses].reverse(),
        );
        break;

      case LiveFeedSortBy.NAME:
        setSortedLiveFeedResponses(
          sortResponses(
            liveFeedQuestionResponses,
            (a: LiveFeedQuestionResponse, b: LiveFeedQuestionResponse) =>
              a.respondentName.localeCompare(b.respondentName),
          ),
        );
        break;

      case LiveFeedSortBy.VOTES:
        setSortedLiveFeedResponses(
          sortResponses(
            liveFeedQuestionResponses,
            (a: LiveFeedQuestionResponse, b: LiveFeedQuestionResponse) =>
              b.upvoteCount - a.upvoteCount,
          ),
        );
        break;

      case LiveFeedSortBy.FAVOURITES:
        setSortedLiveFeedResponses(
          sortResponses(
            liveFeedQuestionResponses,
            (a: LiveFeedQuestionResponse, b: LiveFeedQuestionResponse) =>
              a.isPinned - b.isPinned,
          ),
        );
        break;

      default:
        setSortedLiveFeedResponses([...liveFeedQuestionResponses]);
    }
  }, [liveFeedQuestionResponses, order, sortBy]);

  const handleFilterByDropdown = (dropDownText: LiveFeedStatsDropdownEnum) => {
    onDropDownChange(dropDownText);
  };

  const getFilterByDropdownText = useCallback(
    (dropDownText: LiveFeedStatsDropdownEnum): string => {
      switch (dropDownText) {
        case LiveFeedStatsDropdownEnum.LIVE_FEED:
          return text.liveFeed;
        case LiveFeedStatsDropdownEnum.TOP_VOTED:
          return text.topVoted;
        case LiveFeedStatsDropdownEnum.BOOKMARKED:
          return text.bookmarked;

        default:
          throw new Error(text.invalidDropdownError);
      }
    },
    [text],
  );

  const getSortByDropdownText = useCallback(
    (dropDownText: LiveFeedSortBy): string => {
      switch (dropDownText) {
        case LiveFeedSortBy.SUBMISSION_TIME:
          return text.submissionTime;
        case LiveFeedSortBy.NAME:
          return text.name;
        case LiveFeedSortBy.VOTES:
          return text.votes;
        case LiveFeedSortBy.FAVOURITES:
          return text.favorites;

        default:
          throw new Error(text.invalidDropdownError);
      }
    },
    [text],
  );

  const getNoResponsesText = useCallback(
    (dropDownText: LiveFeedStatsDropdownEnum, noResponsesText?: string) => {
      switch (dropDownText) {
        case LiveFeedStatsDropdownEnum.LIVE_FEED:
          return noResponsesText || text.noComments;
        case LiveFeedStatsDropdownEnum.TOP_VOTED:
          return text.noVotesForComments;
        case LiveFeedStatsDropdownEnum.BOOKMARKED:
          return text.noBookmarkedComments;

        default:
          throw new Error(text.invalidDropdownError);
      }
    },
    [text],
  );

  const onChangeOrder = (order: SortOrder) => {
    if (order === SortOrder.ASC) {
      setOrder(SortOrder.DESC);
    } else {
      setOrder(SortOrder.ASC);
    }
  };

  const noResponsesDiv = (
    <div className={styles.idleContent}>
      <span>{getNoResponsesText(dropDownText, noResponsesText)}</span>
    </div>
  );

  const handleSortByDropdown = (type: LiveFeedSortBy) => {
    setSortBy(type);
  };

  const createDropDownItem = (item: string, sortBy: LiveFeedSortBy) => {
    return (
      <li
        className={styles.dropDownItem}
        onClick={() => handleSortByDropdown(sortBy)}
      >
        {item}
      </li>
    );
  };

  const sortByDropdownItems = (
    <ul>
      {createDropDownItem(text.submissionTime, LiveFeedSortBy.SUBMISSION_TIME)}
      {createDropDownItem(text.name, LiveFeedSortBy.NAME)}
      {dropDownText !== LiveFeedStatsDropdownEnum.BOOKMARKED &&
        createDropDownItem(text.favorites, LiveFeedSortBy.FAVOURITES)}
      {createDropDownItem(text.votes, LiveFeedSortBy.VOTES)}
    </ul>
  );

  const sortByDropDown = (
    <div className={styles.sortByLiveFeedViewDropdown}>
      <LpDropdown
        text={getSortByDropdownText(sortBy)}
        customDropdownBtnClass={styles.dropDown}
      >
        {sortByDropdownItems}
      </LpDropdown>

      <div className={styles.ascOrDescIcon}>
        <WuTooltip content="Reverse order" position="bottom">
          <img
            src={order === SortOrder.DESC ? descendingSvg : ascendingSvg}
            onClick={() => onChangeOrder(order)}
            alt="change answer order"
            className={styles.icon}
            height={16}
          />
        </WuTooltip>
      </div>
    </div>
  );

  const filterByDropdown = (
    <LpDropdown
      text={getFilterByDropdownText(dropDownText)}
      leftIcon={getDropdownIconByText(dropDownText, text.invalidDropdownError)}
      customDropdownBtnClass={styles.dropDown}
    >
      <div
        className={styles.dropDownItem}
        onClick={() =>
          handleFilterByDropdown(LiveFeedStatsDropdownEnum.LIVE_FEED)
        }
      >
        <span className={styles.dropDownItemIcon}>{LiveFeedSvg()}</span>
        <span>
          {getFilterByDropdownText(LiveFeedStatsDropdownEnum.LIVE_FEED)}
        </span>
      </div>
      <div
        className={styles.dropDownItem}
        onClick={() =>
          handleFilterByDropdown(LiveFeedStatsDropdownEnum.TOP_VOTED)
        }
      >
        <span className={styles.dropDownItemIcon}>
          {<LikeSvg width="16px" height="16px" />}
        </span>
        <span>
          {getFilterByDropdownText(LiveFeedStatsDropdownEnum.TOP_VOTED)}
        </span>
      </div>
      <div
        className={styles.dropDownItem}
        onClick={() =>
          handleFilterByDropdown(LiveFeedStatsDropdownEnum.BOOKMARKED)
        }
      >
        <span className={styles.dropDownItemIcon}>
          <FontAwesomeIcon icon={faStar} className={styles.starIcon} />
        </span>
        <span>
          {getFilterByDropdownText(LiveFeedStatsDropdownEnum.BOOKMARKED)}
        </span>
      </div>
    </LpDropdown>
  );

  const dropDownForDesktopScreens = (
    <div className={styles.liveFeedViewDropdown}>
      {filterByDropdown}
      {sortByDropDown}
    </div>
  );

  const filterByDropdownForMobile = (
    <div className={styles.mobileView}>
      <div
        className={`${styles.viewSelectorIconMobile} ${getSelectedIconClass(
          dropDownText,
          LiveFeedStatsDropdownEnum.LIVE_FEED,
        )}`}
        onClick={() =>
          handleFilterByDropdown(LiveFeedStatsDropdownEnum.LIVE_FEED)
        }
      >
        <span>{LiveFeedSvg()}</span>
      </div>

      <div
        className={`${styles.viewSelectorIconMobile} ${getSelectedIconClass(
          dropDownText,
          LiveFeedStatsDropdownEnum.BOOKMARKED,
        )}`}
        onClick={() =>
          handleFilterByDropdown(LiveFeedStatsDropdownEnum.BOOKMARKED)
        }
      >
        <span>
          <FontAwesomeIcon icon={faStar} className={styles.starIcon} />
        </span>
      </div>

      <div
        className={`${styles.viewSelectorIconMobile} ${getSelectedIconClass(
          dropDownText,
          LiveFeedStatsDropdownEnum.TOP_VOTED,
        )}`}
        onClick={() =>
          handleFilterByDropdown(LiveFeedStatsDropdownEnum.TOP_VOTED)
        }
      >
        <span>{<LikeSvg width="16px" height="16px" />}</span>
      </div>
    </div>
  );

  const dropDownForMobileScreens = (
    <div className={styles.mobileHeader}>
      {filterByDropdownForMobile}
      {sortByDropDown}
    </div>
  );

  return (
    <>
      <div className={styles.container}>
        {dropDownForDesktopScreens}
        {dropDownForMobileScreens}
      </div>
      {isLoading && (
        <div className={styles.idleContent}>
          <LpSpinner message="Loading..." />
        </div>
      )}

      {!isLoading && (
        <>
          {liveFeedQuestionResponses.length === 0 && noResponsesDiv}
          {liveFeedQuestionResponses.length > 0 && (
            <>
              <div
                className={
                  resultScreen
                    ? styles.commentList
                    : styles.commentListForLiveSession
                }
              >
                {sortedLiveFeedResponses.map(response => (
                  <LiveFeedComment
                    key={response.id}
                    liveFeedResponse={response}
                    commentPinUnpin={commentPinUnpin}
                  />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

const getDropdownIconByText = (
  dropDownText: LiveFeedStatsDropdownEnum,
  errorText: string,
): React.ReactElement => {
  switch (dropDownText) {
    case LiveFeedStatsDropdownEnum.LIVE_FEED:
      return LiveFeedSvg();
    case LiveFeedStatsDropdownEnum.TOP_VOTED:
      return <LikeSvg width="16px" height="16px" />;
    case LiveFeedStatsDropdownEnum.BOOKMARKED:
      return <FontAwesomeIcon icon={faStar} className={styles.starIcon} />;

    default:
      throw new Error(errorText);
  }
};

const getSelectedIconClass = (
  dropDownText: LiveFeedStatsDropdownEnum,
  iconLabel: LiveFeedStatsDropdownEnum,
): string => {
  return dropDownText === iconLabel ? styles.selectedIcon : '';
};
