import { useState, useEffect } from 'react';

export const useScreenResize = (onResize?: () => void) => {
  // State to store the current window width and height
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Handler to update the window size state
    const handleResize = () => {
      onResize && onResize();
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', handleResize);
  }, [onResize]); // Empty dependency array ensures this runs once on mount and cleanup on unmount

  return windowSize;
};
