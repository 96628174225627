import { useWordCloudQuestionResult } from 'src/hooks/store-hooks';
import { LivePollSessionHeaderForZoom } from '../../../session-header/LivePollSessionHeaderForZoom';
import styles from './WordCloudQuestionStartedForZoom.module.css';
import { LpWordCloudImage } from '@livepolls/ui-components/src/components/word-cloud/LpWordCloud';

interface Props {
  questionTitle: string;
  questionId: string;
  livePollId: number;
  livePollSessionId: number;
}

export const WordCloudQuestionStartedForZoom = ({
  questionTitle,
  livePollId,
  livePollSessionId,
  questionId,
}: Props) => {
  const { data: questionResult } = useWordCloudQuestionResult(
    livePollId,
    livePollSessionId,
    questionId,
  );

  const responses = questionResult?.wordCloudResponses;

  return (
    <>
      <LivePollSessionHeaderForZoom />
      <div className={styles.container}>
        <div className={styles.questionText}>
          <strong> Question: </strong>
          {questionTitle}
        </div>

        {responses && responses.length > 0 && (
          <LpWordCloudImage
            wordCloudQuestionResponses={responses!}
            text={{
              mentions: 'mentions',
              popularMentions: 'Popular mentions',
            }}
          />
        )}
        {(!responses || responses.length === 0) && (
          <div className={styles.emptyState}>No responses</div>
        )}
        <></>
      </div>
    </>
  );
};
