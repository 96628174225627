import {
  LIVE_FEED_PAGINATION_ITEMS_PER_PAGE,
  LIVE_FEED_PAGINATION_START_PAGE_INDEX,
} from '@livepolls/ui-components/src/constants/live-feed-pagination.constants';
import { LiveFeedStatsDropdownEnum } from '@livepolls/ui-components/src/enums/live-feed-stats-dropdown.enum';
import { ILiveFeedQuestionResultPaginationParams } from '@livepolls/ui-components/src/interfaces/live-feed-question-result-pagination-params.interface';

export const getDefaultValuesForLiveFeedResultPagination =
  (): ILiveFeedQuestionResultPaginationParams => {
    return {
      liveFeedView: LiveFeedStatsDropdownEnum.LIVE_FEED,
      perPage: LIVE_FEED_PAGINATION_ITEMS_PER_PAGE,
      pageIndex: LIVE_FEED_PAGINATION_START_PAGE_INDEX,
    };
  };
