import styles from './TeamChart.module.scss';
import ribbonSvg from '../../../images/blue-ribbon.svg';
import { LpPieChart } from '../quiz/teams/LpPieChart';
import { appendPositionPrefix } from '../../../utils/string.util';
import { useScreenResize } from '../../../utils/getScreenSize.util';
import { isDesktopDevice } from '../../../utils/isDesktopDevice.util';

interface TeamResponse {
  cardId: string;
  teamUid: string;
  name: string;
  color: string;
  percentage: number;
}

interface Props {
  teamResponses: TeamResponse[];
}

export const TeamChart = ({ teamResponses }: Props) => {
  const topTeam = teamResponses[0];
  const otherTeams = teamResponses.slice(1);
  const { width } = useScreenResize();
  const isDesktopView = isDesktopDevice(width);

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <span className={styles.topTeamName}>
          <img src={ribbonSvg} alt="ribbon" className={styles.ribbonImg} />
          {appendPositionPrefix(topTeam.name, 1)}
        </span>
        <div className={styles.topTeamPieChartContainer}>
          <LpPieChart
            width={48}
            height={48}
            color={topTeam.color}
            filledPercentage={topTeam.percentage}
          />
          <span className={styles.topTeamPercentageText}>
            {topTeam.percentage} <span>%</span>
          </span>
        </div>
      </div>
      <div className={styles.rightSide}>
        {!isDesktopView && topTeamRow(topTeam, 1)}
        {otherTeams.map((team, idx) => teamRow(team, idx + 2))}
      </div>
    </div>
  );
};

const teamRow = (team: TeamResponse, idx: number) => {
  return (
    <div
      key={`${team.cardId}-${team.teamUid}`}
      className={`${styles.teamNameWithPieChartRow} ${
        idx > 2 ? styles.borderTop : ''
      }`}
    >
      <div className={`${styles.teamName} ${idx === 1 && styles.boldTeamName}`}>
        <span>{appendPositionPrefix(team.name, idx)}</span>
      </div>
      <div className={styles.pieChartContainer}>
        <LpPieChart
          width={24}
          height={24}
          color={team.color}
          filledPercentage={team.percentage}
        />
        <span className={styles.percentageText}>{team.percentage}%</span>
      </div>
    </div>
  );
};

const topTeamRow = (team: TeamResponse, idx: number) => {
  return (
    <div
      key={`${team.cardId}-${team.teamUid}`}
      className={`${styles.teamNameWithPieChartRow}`}
    >
      <div className={`${styles.teamName} ${idx === 1 && styles.boldTeamName}`}>
        <img
          src={ribbonSvg}
          alt="ribbon"
          className={styles.ribbonImgForSmallDevices}
        />
        <span>{appendPositionPrefix(team.name, idx)}</span>
      </div>
      <div className={styles.pieChartContainer}>
        <LpPieChart
          width={24}
          height={24}
          color={team.color}
          filledPercentage={team.percentage}
        />
        <span className={styles.percentageText}>{team.percentage}%</span>
      </div>
    </div>
  );
};
