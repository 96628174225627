import { LpDropdown } from '../../../dropdown/LpDropdown';
import { ChartView } from '../../../../enums/chart-view.enum';
import styles from './LpSwipeQuestionDropownForQuiz.module.css';

interface Props {
  onChangeView: (currentView: ChartView) => void;
  currentView: ChartView;
  text: { sortBy: string; default: string; accuracy: string };
}

export const LpSwipeQuestionDropownForQuiz = ({
  onChangeView,
  currentView,
  text,
}: Props) => {
  const getDropDownText = () => {
    if (currentView === ChartView.CHRONOLOGICAL) {
      return text.default;
    } else if (currentView === ChartView.RIGHT_SWIPES_RESPONSE_RATE) {
      return text.accuracy;
    }
    return text.default;
  };

  return (
    <div>
      <div className={styles.sortByContainer}>
        <div>{text.sortBy}</div>
        <div>
          <LpDropdown
            text={getDropDownText()}
            customDropdownBtnClass={styles.dropDown}
            customDropdownContentClass={styles.dropDownContent}
            customDropdownTextClass={styles.dropdownText}
          >
            <ul>
              <li
                className={styles.dropDownItem}
                onClick={() => onChangeView(ChartView.CHRONOLOGICAL)}
                key="default"
              >
                {text.default}
              </li>

              <li
                className={styles.dropDownItem}
                onClick={() =>
                  onChangeView(ChartView.RIGHT_SWIPES_RESPONSE_RATE)
                }
                key="accuracy"
              >
                {text.accuracy}
              </li>
            </ul>
          </LpDropdown>
        </div>
      </div>
    </div>
  );
};
