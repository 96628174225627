import React from 'react';
import { Socket, io } from 'socket.io-client';
import { ADMIN_WS_CONNECTION_URL } from 'src/constants/app.constants';
import { pageReload } from 'src/utils/reload.util';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import styles from './socketContext.module.css';

interface Props {
  children: React.ReactNode;
}

interface SocketContextValue {
  connected: boolean;
  socket: Socket;
  handleSocketError: (handler: (data: any) => void) => (data: any) => void;
}

const SocketContext = React.createContext<SocketContextValue | undefined>(
  undefined,
);

const handleRefresh = (event: React.MouseEvent<HTMLAnchorElement>) => {
  event.preventDefault();
  pageReload();
};

const refreshMessage = (
  <span>
    Click{' '}
    <a
      href="/"
      onClick={handleRefresh}
      data-testid="reloadPage"
      className={styles.refreshLink}
    >
      here
    </a>{' '}
    to refresh the page
  </span>
);

export const SocketContextProvider = ({ children }: Props) => {
  const [connected, setConnected] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [connectionError, setConnectionError] = React.useState<
    Error | undefined
  >(undefined);
  const [error, setError] = React.useState<Error | undefined>(undefined);
  const socketRef = React.useRef<Socket | null>(null);

  if (!socketRef.current) {
    const socket = io(ADMIN_WS_CONNECTION_URL, {
      path: '/ws/admin',
      withCredentials: true,
    });

    socketRef.current = socket;

    socket.on('connect', () => {
      /* We don't want to send events that were sent during offline state
      Read more here: https://socket.io/docs/v4/client-offline-behavior/ */
      socket.sendBuffer = [];
      setConnected(true);
      setLoading(false);
      setConnectionError(undefined);
      setError(undefined);
    });

    socket.on('disconnect', reason => {
      console.log('socket disconnected: ', reason);
      setConnected(false);
      setLoading(true);
    });

    socket.on('connect_error', error => {
      setConnectionError(error);
    });

    socket.on('close', () => {
      setLoading(true);
      socket.connect();
    });
  }

  React.useEffect(() => {
    return () => {
      setConnected(false);
      socketRef.current!.disconnect();
    };
  }, []);

  const handleSocketError = (handler: (data: any) => void) => {
    return (data: any) => {
      if (data.type === 'error') {
        setError(new Error(data.message));
        return;
      }

      handler(data);
    };
  };
  const value = React.useMemo(
    () => ({
      connected,
      socket: socketRef.current!,
      handleSocketError,
    }),
    [connected],
  );

  if (connectionError) {
    return (
      <>
        <div className={styles.socketDisconnectionMessage}>
          Oops, connection error ({connectionError.message}).
          {refreshMessage}
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <div className={styles.socketDisconnectionMessage}>
          Oops, an error occurred ({error.message}).
          {refreshMessage}
        </div>
      </>
    );
  }

  if (loading) {
    return <LpSpinner message="Loading..." />;
  }

  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => {
  const context = React.useContext(SocketContext);
  if (!context) {
    throw new Error(`useSocket must be used within a SocketContextProvider`);
  }
  return context.socket;
};

export const useSocketConnected = () => {
  const context = React.useContext(SocketContext);
  if (!context) {
    throw new Error(
      `useSocketConnected must be used within a SocketContextProvider`,
    );
  }
  return context.connected;
};

export const useHandleSocketError = () => {
  const context = React.useContext(SocketContext);
  if (!context) {
    throw new Error(
      `useHandleSocketError must be used within a SocketContextProvider`,
    );
  }

  return context.handleSocketError;
};
