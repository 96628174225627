import { IDownloadReportStore } from './useDownloadReport.store';

export const downloadedFilesSelector = (state: IDownloadReportStore) =>
  state.files;
export const setDownloadedFilesSelector = (state: IDownloadReportStore) =>
  state.setFiles;
export const addFileSelector = (state: IDownloadReportStore) => state.addFile;
export const startDownloadableReportCreationSelector = (
  state: IDownloadReportStore,
) => state.startDownloadableReportCreation;
export const removeFileSelector = (state: IDownloadReportStore) =>
  state.removeFile;
export const updateProgressSelector = (state: IDownloadReportStore) =>
  state.updateProgress;
export const updateErrorSelector = (state: IDownloadReportStore) =>
  state.updateError;
export const updateIsDownloadedSelector = (state: IDownloadReportStore) =>
  state.updateIsDownloaded;
