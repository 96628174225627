import { useGetAllLiveFeedQuestionResult } from 'src/hooks/store-hooks';
import { LivePollSessionHeaderForZoom } from '../../../session-header/LivePollSessionHeaderForZoom';
import styles from './LiveFeedQuestionFinishedForZoom.module.css';
import { LpLiveFeedStats } from '@livepolls/ui-components/src/components/live-feed-question-report/LpLiveFeedStats';
import { t } from 'i18next';
import { useLiveFeedCommentPinUnpinMutation } from 'src/contexts/runLivePollSessionContext';
import { LiveFeedStatsDropdownEnum } from '@livepolls/ui-components/src/enums/live-feed-stats-dropdown.enum';
import { useState } from 'react';

interface Props {
  questionTitle: string;
  questionId: string;
  livePollId: number;
  livePollSessionId: number;
}

export const LiveFeedQuestionFinishedScreenForZoom = ({
  questionTitle,
  questionId,
  livePollId,
  livePollSessionId,
}: Props) => {
  const [dropDownText, setDropDownText] = useState<LiveFeedStatsDropdownEnum>(
    LiveFeedStatsDropdownEnum.LIVE_FEED,
  );

  const { data: questionResult } = useGetAllLiveFeedQuestionResult(
    livePollId,
    livePollSessionId,
    questionId,
  );

  return (
    <>
      <LivePollSessionHeaderForZoom />
      <div className={styles.container}>
        <div className={styles.questionText}>
          <strong> Question: </strong>
          {questionTitle}
        </div>
        {questionResult?.liveFeedResponses.length === 0 && (
          <div className={styles.noComments}>No comments</div>
        )}

        {questionResult && questionResult?.liveFeedResponses?.length > 0 && (
          <LpLiveFeedStats
            isLoading={false}
            dropDownText={dropDownText}
            liveFeedQuestionResponses={questionResult?.liveFeedResponses}
            totalItems={questionResult?.liveFeedResponses.length}
            onDropDownChange={setDropDownText}
            commentPinUnpin={useLiveFeedCommentPinUnpinMutation}
            noResponsesText={`${t('liveFeedInfoMsg')}...`}
            text={{
              liveFeed: t('allResponses'),
              topVoted: t('voted'),
              bookmarked: t('favorites'),
              comments: t('comments'),
              showing: t('showing'),
              showUpTo: t('showUpTo'),
              of: t('of'),
              to: t('to'),
              invalidDropdownError: t('invalidDropdownError'),
              noComments: t('noComments'),
              noVotesForComments: t('noVotesForComments'),
              noBookmarkedComments: t('noBookmarkedComments'),
              submissionTime: t('submissionTime'),
              name: t('name'),
              favorites: t('favorites'),
              votes: t('votes'),
            }}
          />
        )}
      </div>
    </>
  );
};
