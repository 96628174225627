import { LpLiveFeedStats } from '@livepolls/ui-components/src/components/live-feed-question-report/LpLiveFeedStats';
import { LivePollSessionHeaderForZoom } from '../../../session-header/LivePollSessionHeaderForZoom';
import styles from './LiveFeedQuestionStartedForZoom.module.css';
import { useGetAllLiveFeedQuestionResult } from 'src/hooks/store-hooks';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { LiveFeedStatsDropdownEnum } from '@livepolls/ui-components/src/enums/live-feed-stats-dropdown.enum';
import { useLiveFeedCommentPinUnpinMutation } from 'src/contexts/runLivePollSessionContext';
import { LiveFeedPinUnpin } from '@livepolls/ui-components/src/enums/livefeed-pin-unpin.enum';
import { LiveFeedQuestionResponse } from '@livepolls/ui-components/src/interfaces/livefeed-question-response';

interface Props {
  questionTitle: string;
  questionId: string;
  livePollId: number;
  livePollSessionId: number;
}
export const LiveQuestionStartedForZoom = ({
  questionTitle,
  questionId,
  livePollId,
  livePollSessionId,
}: Props) => {
  const [dropDownText, setDropDownText] = useState<LiveFeedStatsDropdownEnum>(
    LiveFeedStatsDropdownEnum.LIVE_FEED,
  );
  const [liveFeeQuestionResponses, setLiveFeedQuestionResponses] = useState<
    LiveFeedQuestionResponse[]
  >([]);

  const { data: questionResult } = useGetAllLiveFeedQuestionResult(
    livePollId,
    livePollSessionId,
    questionId,
  );

  useEffect(() => {
    if (!questionResult?.liveFeedResponses) {
      return;
    }

    const currLiveFeedQuestionResponses = questionResult?.liveFeedResponses;
    switch (dropDownText) {
      case LiveFeedStatsDropdownEnum.LIVE_FEED:
        setLiveFeedQuestionResponses(currLiveFeedQuestionResponses);
        break;

      case LiveFeedStatsDropdownEnum.TOP_VOTED:
        const sortedByUpvote = currLiveFeedQuestionResponses.filter(
          response => response.upvoteCount > 0,
        );
        sortedByUpvote.sort((a, b) => b.upvoteCount - a.upvoteCount);

        setLiveFeedQuestionResponses(sortedByUpvote);
        break;

      case LiveFeedStatsDropdownEnum.BOOKMARKED:
        const pinnedResponses = currLiveFeedQuestionResponses.filter(
          response => response.isPinned === LiveFeedPinUnpin.PIN,
        );
        setLiveFeedQuestionResponses(pinnedResponses);
        break;

      default:
        throw new Error(t('invalidDropdownError'));
    }
  }, [questionResult, dropDownText]);

  return (
    <>
      <LivePollSessionHeaderForZoom />
      <div className={styles.container}>
        <div className={styles.questionText}>
          <strong> Question: </strong>
          {questionTitle}
        </div>
        {questionResult?.liveFeedResponses.length === 0 && (
          <div className={styles.noComments}>No comments</div>
        )}

        {questionResult && questionResult?.liveFeedResponses?.length > 0 && (
          <LpLiveFeedStats
            resultScreen={false}
            isLoading={false}
            dropDownText={dropDownText}
            liveFeedQuestionResponses={liveFeeQuestionResponses}
            totalItems={questionResult?.liveFeedResponses.length}
            onDropDownChange={setDropDownText}
            commentPinUnpin={useLiveFeedCommentPinUnpinMutation}
            noResponsesText={`${t('liveFeedInfoMsg')}...`}
            text={{
              liveFeed: t('allResponses'),
              topVoted: t('voted'),
              bookmarked: t('favorites'),
              comments: t('comments'),
              showing: t('showing'),
              showUpTo: t('showUpTo'),
              of: t('of'),
              to: t('to'),
              invalidDropdownError: t('invalidDropdownError'),
              noComments: t('noComments'),
              noVotesForComments: t('noVotesForComments'),
              noBookmarkedComments: t('noBookmarkedComments'),
              submissionTime: t('submissionTime'),
              name: t('name'),
              favorites: t('favorites'),
              votes: t('votes'),
            }}
          />
        )}
      </div>
    </>
  );
};
